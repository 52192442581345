// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track'

// Styling
import './Home.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import LandingAnimation from '../../components/LandingAnimation/LandingAnimation';
import LaunchVideo from '../../components//LaunchVideo/LaunchVideo';
import MasonryGallery from '../../components/MasonryGallery/MasonryGallery';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';

// Assets
import Session from '../../classes/session';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class Home extends BaseComponent {

    constructor(props) {
        super(props);
        this.clubSelectRef = React.createRef();
    }

    state = {
        animateInState: true,
        content: {},
        clubs: [],
        gallery: [],
        loading: true,
        step: Session.launchAnimationPlayed ? 'home' : 'landing',
        launchVideoActive: false
    }

    // Landing icon has finished playing - display the page
    animationCompleted = () => {
        Session.launchAnimationPlayed = true;
        this.setState({ step: 'home' }, this.applyAnimation);
    }

    updateClub = async () => {
        const club = this.clubSelectRef.current.value;
        const slug = await Storyblok.clubSlugFromId( club )
        this.props.history.push(`/${this.activeShowName}/club/${slug}`);
    }
    
    viewPage(type, id) {
        switch (type) {
            case 'club':
                Storyblok.clubSlugFromId( id )
                    .then( slug => this.props.history.push(`/${this.activeShowName}/club/${slug}`) );
                break;
            case 'masterclass':
                Storyblok.masterclassSlugFromId( id )
                    .then( slug => this.props.history.push(`/${this.activeShowName}/masterclass/${slug}`) );
                break;
            case 'showcase':
                const event = new CustomEvent('showcase', { detail: id });
                window.dispatchEvent(event);
                break;
            default: return;
        }
    }

    applyAnimation() {
        if (this.state.step === 'home') {
            this.timer = setTimeout( () => this.setState({animateInState: false}))
        }
    }

    getData() {
        Storyblok.fetchShowPage('home').then( (home) => {
            this.setState({content: home});
            Storyblok.fetchHomePageImages().then( (homepageContent) => {
                Storyblok.fetchShowMasterclasses().then( (masterclasses) => {
                    Storyblok.fetchShowClubs().then( (clubs) => {
                        const gallery = homepageContent.map( (item, index) => {
                            const contentMatch = item.component === 'Club Page' ? 
                                clubs.find( (club) => item.uuid === club.uuid ) :
                                masterclasses.find( (masterclass) => item.uuid === masterclass.uuid);

                            if (contentMatch) {
                                const cta = item.component === 'Club Page' ? 
                                    (<p className="white small bold">{this.state.content.copy_view_club_page}</p>) :
                                    (<p className="white small bold">{this.state.content.copy_view_masterclass}</p>);

                                const image = Object.assign({}, item);
                                delete image.uuid;
                                delete image.component;

                                return {
                                    image,
                                    card: { 
                                        name: contentMatch.shortName ? contentMatch.shortName : contentMatch.name,
                                        subname: contentMatch.category && contentMatch.category.display_name ? contentMatch.category.display_name : null,
                                        cta
                                    },
                                    callback: item.component === 'Club Page' ? () => this.viewPage('club', item.uuid) : () => this.viewPage('masterclass', item.uuid)
                                }
                            } else {
                                return null;
                            }
                        }).filter( (item) => item );

                        this.setState({loading: false, clubs, gallery}, this.applyAnimation);
                    });
                });
            });
        })
    }

    componentDidMount() {
        Track.page( 'Home', '/home' );
        this.getData();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const homeClasses = this.state.animateInState ? 'Home animate-page-in-state' : 'Home';
        const dropdownClasses = this.state.club ? 'selector active' : 'selector';
        const onLaunchVideoOpen = () => this.setState( { launchVideoActive: true } )
        const onLaunchVideoClose = () => this.setState( { launchVideoActive: false } )

        const landingAnimation = this.state.step === 'landing' ? <LandingAnimation completed={this.animationCompleted} /> : null;
        const launchVideo = this.state.launchVideoActive ? <LaunchVideo onClose={onLaunchVideoClose}></LaunchVideo> : null;

        const launchVideoHtml = () => {
            // Don't show the launch video link
            if (!this.state.content.include_launch_video_link) {
                return '';
            }

            const p1 = this.state.content.copy_launch_video_link.indexOf( '#' );
            const p2 = this.state.content.copy_launch_video_link.indexOf( '#', p1+1 );
          
            if (p1>=0 && p2>p1) {
                return (
                    <p className="regular bold pad-bottom">
                        { this.state.content.copy_launch_video_link.substr(0, p1) }
                        <span className="bold red underline" onClick={onLaunchVideoOpen}>{ this.state.content.copy_launch_video_link.substr(p1+1, (p2 - p1) -1 ) }</span>
                        { this.state.content.copy_launch_video_link.substr(p2+1) }
                    </p>  
                )  
            }
            return '';
        }

        const titleImage = this.state.content.title_image && this.state.content.title_image.filename ?
            ( <img className="title-image" src={this.state.content.title_image.filename} alt="title" /> ) : ''; 

        const titleCopy = this.state.content.copy_title ?
            ( <h1 className="xxl bold red">{this.state.content.copy_title}</h1> ) : ''; 

        const template = this.state.loading ? ( <div className="loading"><Loader /></div> ) : (
            <Cursor>
                {launchVideo}
                <ScrollDirection>
                    { landingAnimation }
                    { this.state.step === 'home' ? (
                        <div className={homeClasses}>
                            <div className="LayoutColumn has-theme theme-white" style={{justifyContent: 'center'}}>
                                <div className="Text">
                                    <div className="text-content">
                                        { titleImage }
                                        { titleCopy }
                                        <p className="pad-bottom">
                                            <span className="regular bold">{this.state.content.copy_message}</span>
                                        </p>
                                        { launchVideoHtml() }
                                    </div>
                                </div>
                                <div className={dropdownClasses}>
                                    { !Session.isIE && (
                                        <label htmlFor="select-club" className="label red">
                                            <p className="regular bold">{this.state.content.copy_select_club}</p>
                                        </label>
                                    )}
                                    <select ref={this.clubSelectRef} id="select-club" className="select regular bold" defaultValue="" onChange={this.updateClub} tabIndex="0">
                                        <option value="">{ Session.isIE ? this.state.content.copy_select_club : '' }</option>
                                        { this.state.clubs.map( (club) => (
                                            <option key={club.uuid} value={club.uuid}>{club.longName}</option>
                                        )) }
                                    </select>
                                    <ArrowDown className="dropdown-icon" alt="Down Icon" />
                                </div>
                            </div>
                            <div className="LayoutColumn reduced-pad-bottom gallery-section">
                                <MasonryGallery items={this.state.gallery} large={true} />
                            </div>
                        </div>
                    ) : null }
                </ScrollDirection>
            </Cursor>
        );

        return template;
    }
}

export default Home;