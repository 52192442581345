/*global gtag*/

import { Storyblok } from '@imagination/sc-storyblok-lib-js';

class Track {

    static page( title, path, extra ) {

        const showName = Storyblok.site.active_show.name;
        const fullPath = `show/${showName}/${path}`.replace( /\/\//g, '/' );
        const params = Object.assign({
            page_path: fullPath,
            page_title: `Summer Show - ${title}`
        }, extra || {} );
        gtag('event', 'page_view', params );

    }

}

export default Track;