import React, { Component } from 'react';
import './HoverCta.scss';

// Assets
import { ReactComponent as PauseIcon } from '../../assets/cta/pause.svg';
import { ReactComponent as PlayIcon } from '../../assets/cta/play.svg';
import { ReactComponent as GalleryIcon } from '../../assets/cta/gallery.svg';

class HoverCta extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    state = {
        alternateTheme: false,
        style: {
            transition: 'transform 0.5s ease, width 0.25s ease, height 0.25s ease',
            transform: `translate(0, 0)`
        }
    }
    touchDevice = false;

    getButtonContent(type) {
        switch (type) {
            case 'text':
                return (<p className="hover-text">{this.props.text}</p>)
            case 'pause':
                return (<PauseIcon className="button-icon" alt="Pause Icon" />)
            case 'play':
                return (<PlayIcon className="button-icon" alt="Play Icon" />)
            case 'gallery':
                return (<GalleryIcon className="button-icon" alt="View Gallery Icon" />)
            default:
                return null;
        }
    }

    checkIfAlternateTheme(el) {
        let isInContent = false;
        // walk up the tree checking for the given class
        while (el && !isInContent) {
            isInContent = el && typeof el.className === 'string' && el.className.includes('theme-red') ? true : false;
            el = el.parentElement;
        }
        return isInContent;
    }

    touchStartHandler = () => {
        this.touchDevice = true;
    }

    // Handles when mouse is moved within element. Finds X/Y position and moves icon
    mouseMoveHandler = (event) => {
        if (!this.touchDevice) {
            const boundingRect = this.containerRef.current.getBoundingClientRect();
            const distanceX = event.clientX - boundingRect.left;
            const distanceY = event.clientY - boundingRect.top;

            this.setState({'style': {
                transition: 'width 0.25s ease, height 0.25s ease',
                transform: `translate( ${distanceX}px, ${distanceY}px )`
            }});
        }
    }

    // When mouse leaves, moves icon back to top left
    mouseLeaveHandler = () => {
        if (!this.touchDevice) {
            this.setState({style: {
                transition: 'transform 0.5s ease, width 0.25s ease, height 0.25s ease',
                transform: `translate(0, 100%)`
            }});
        } else {
            this.touchDevice = false;
        }
    }

    componentDidMount() {
        const alternateTheme = this.checkIfAlternateTheme(this.containerRef.current) ? true : false;
        if (alternateTheme) {
            this.setState({alternateTheme});
        }

        if (matchMedia('(pointer:fine)').matches) {
            // Device has a mouse
            this.setState({style: {
                transition: 'transform 0.5s ease, width 0.25s ease, height 0.25s ease',
                transform: `translate(0, 100%)`
            }});
        }
    }

    render() {

        const buttonContent = this.getButtonContent(this.props.icon);

        let classes = this.state.alternateTheme ? `HoverCta theme-white` : `HoverCta`;
        classes = this.props.icon ? `${classes} icon-${this.props.icon}` : classes;

        return (
            <div
                ref={this.containerRef}
                className={classes}
                onTouchStart={this.touchStartHandler}
                onMouseMove={this.mouseMoveHandler}
                onMouseLeave={this.mouseLeaveHandler}
                onClick={this.props.clicked} >
                {this.props.children}
                <div
                    className="hover-cta"
                    style={this.state.style} >
                    <div className="icon">
                        {buttonContent}
                    </div>
                </div>
            </div>
        )
    }
}

export default HoverCta;