import React from 'react';
import './ModuleBlock.scss';

import Image from '../Image/Image';
import LayoutColumn from '../LayoutColumn/LayoutColumn';
import LayoutRow from '../LayoutRow/LayoutRow';
import MasterclassList from '../MasterclassList/MasterclassList';
import PartnersList from '../PartnersList/PartnersList';
import Section from '../Section/Section';
import Text from '../Text/Text';
import Title from '../Title/Title';
import Video from '../Video/Video';
import Hyperlink from '../Hyperlink/Hyperlink';

const ModuleBlock = (props) => {

    const getElementFromComponentName = (data) => {
        switch (data.component) {
            case 'Image':
                return (<Image data={data} />);
            case 'Layout Column':
                return (<LayoutColumn data={data} />);
            case 'Layout Row':
                return (<LayoutRow data={data} />);
            case 'Masterclass List':
                return (<MasterclassList data={data} />);
            case 'Partners List':
                return (<PartnersList data={data} />);
            case 'Section':
                return (<Section data={data} />);
            case 'Text':
                return (<Text data={data} />);
            case 'Title':
                return (<Title data={data} />);
            case 'Video':
                return (<Video data={data} />);
            case 'Hyperlink': 
                return (<Hyperlink data={data} />);
            default: return null;
        }
    }
    
    return getElementFromComponentName(props.data);
}

export default ModuleBlock;