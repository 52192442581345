import React from 'react';

// Styling
import './PartnersList.scss';

// Components
import AnimateElementIn from '../../AnimateIn/AnimateElementIn/AnimateElementIn';

const PartnersList = (props) => {
    const numberOfLines = props.data.number_of_lines ? props.data.number_of_lines : 10;
    const charactersPerLine = props.data.items ? 
        props.data.items.reduce( (count, next) => count + next.name.length, 0) / numberOfLines : 100;
    
    let currentCharacterCount = 0;
    const groups = props.data.items ? props.data.items.reduce( (acc, item, index) => {
        const arrIndex = Math.floor(currentCharacterCount / charactersPerLine);
        currentCharacterCount += item.name.length;
        if (acc[arrIndex]) {
            acc[arrIndex].push(item)
        } else {
            acc[arrIndex] = [item];
        }
        return acc;
    }, []) : [];

    const lists = groups.map( (group, groupIndex) => (
        <div className="group" key={`group_${groupIndex}`}>
            { group.map( (item, itemIndex) => (
                <a className="link medium semi-bold" key={`item_${item._uid}`} href={item.url} target="_blank" rel="noopener noreferrer">{item.name}{ groupIndex === groups.length - 1 && itemIndex === group.length - 1 ? '.' : ', '}</a>
            )) }
        </div>
    ));
    
    let classes = props.data.text_colour ? `PartnersList colour-${props.data.text_colour}`: 'PartnersList';
    classes = props.data.hover_colour ? `${classes} hover-${props.data.hover_colour}`: classes;

    return (
        <AnimateElementIn>
            <div className={classes}>
                {lists}
            </div>
        </AnimateElementIn>
    )
}

export default PartnersList;