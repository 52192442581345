// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Club.scss';

import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import ModuleBlock from '../../components/Modules/ModuleBlock/ModuleBlock';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class Club extends BaseComponent {

    state = {
        id: null,
        structure: null,
        prev_id : null
    }

    constructor( props ) {
        super( props );
        this.scrollerRef = React.createRef();
    }

    componentDidMount() {
        this.setState( { id: this.props.match.params.id } );
    }

    componentDidUpdate() {
        if (this.state.prev_id !== this.state.id) {
            Storyblok.fetchClubPage( this.state.id, true ).then( (club) => {
                this.setState({ structure: club.structure, prev_id: this.state.id });
                this.scrollerRef.current.reset();
                Track.page( 'Club Page', '/club', {
                    custom_map: {
                        dimension1: 'club_name'
                    },
                    club_name: club.longName
                });
            
            }).catch( err => this.props.history.push( `/${Storyblok.site.active_show.name}` ) );
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newId = props.match.params.id;
        return (newId && newId !== state.id) ? { id: newId } : null;
    }

    render() {
        return (
            <Cursor>
                <ScrollDirection ref={this.scrollerRef}>
                    <div className="Club">
                        { this.state.structure ? this.state.structure.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : <Loader /> }
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default Club;