import React, { Component } from 'react';

// Components
import HoverCta from '../HoverCta/HoverCta';

// Styling
import './SelfPortraitsVideo.scss';

// Assets
import fullscreen from '../../assets/images/fullscreen.png';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

class SelfPortraitsVideo extends Component {

    constructor(props) {
        super(props);
        this.progressRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.videoRef = React.createRef();
    }

    state = {
        loaded: false,
        playing: false,
        duration: null,
        current: null,
        fullscreen: false
    };

    currentlyTouch = false;
    scrubbing = false;

    setNewTime(time) {
        if (time !== null) {
            this.setState({current: time});
            this.videoRef.current.currentTime = time;
        }
    }

    findNewTime = (event) => {
        if (this.state.duration) {
            const rect = this.progressRef.current.getBoundingClientRect();
            const percentX = (event.clientX - rect.left) / rect.width;
            const seconds = this.state.duration * percentX;
            return seconds < 0 || seconds > this.state.duration ? null : seconds;
        } else {
            return null;
        }
    }

    startTouchScrub = (event) => {
        if (event.targetTouches) {
            this.scrubbing = true;
            this.currentlyTouch = true;
            const time = this.findNewTime(event.targetTouches[0]);
            this.setNewTime(time);
        }
    }

    continueTouchScrub = (event) => {
        if (event.targetTouches && this.scrubbing && !this.settingNewTime) {
            const time = this.findNewTime(event.targetTouches[0]);
            this.setNewTime(time);
        }
    }

    stopTouchScrub = (event) => {
        this.scrubbing = false;
    }

    startScrub = (event) => {
        if (!this.currentlyTouch) {
            this.scrubbing = true;
            const time = this.findNewTime(event);
            this.setNewTime(time);
        }
    }

    continueScrub = (event) => {
        if (!this.currentlyTouch && this.scrubbing && !this.settingNewTime) {
            const time = this.findNewTime(event);
            this.setNewTime(time);
        }
    }

    stopScrub = () => {
        this.scrubbing = false;
        this.currentlyTouch = false;
    }

    fullscreenListener = () => {
        const fullscreen = document.fullscreenElement ? true : false;
        this.setState({fullscreen});
    }

    toggleFullscreen = (event) => {
        event.stopPropagation();
        const fullscreen = this.state.fullscreen;
        if (fullscreen) {
            document.exitFullscreen();
        } else {
            if (this.wrapperRef.current.requestFullscreen) {
                this.wrapperRef.current.requestFullscreen();
            }
        }
    }

    onVideoTimeUpdate = () => {
        this.setState({duration: this.videoRef.current.duration, current: this.videoRef.current.currentTime});
    }

    onVideoPlay = () => {
        this.setState({playing: true});
    }

    onVideoPause = () => {
        this.setState({playing: false});
    }

    onVideoEnd = () => {
        this.videoRef.current.currentTime = 0;
        this.setState({playing: false});
    }

    changePlayStatus = () => {
        if (this.state.playing) {
            this.videoRef.current.pause();
        } else {
            this.videoRef.current.play();
        }
    }

    convertToMMSS(time) {
        const rounded = Math.round(time);
        const minutes = Math.floor(rounded / 60);
        const seconds = Math.floor(rounded % 60);
        return `${('0' + (minutes)).substr(-2)}:${('0' + (seconds)).substr(-2)}`;
    }

    setupVideo() {
        this.videoRef.current.volume = 1;
        this.videoRef.current.addEventListener('timeupdate', this.onVideoTimeUpdate);
        this.videoRef.current.addEventListener('play', this.onVideoPlay);
        this.videoRef.current.addEventListener('pause', this.onVideoPause);
        this.videoRef.current.addEventListener('ended', this.onVideoEnd);
    }

    componentDidMount() {
        this.setupVideo();
        document.addEventListener('fullscreenchange', this.fullscreenListener);
        document.addEventListener('mousemove', this.continueScrub);
        document.addEventListener('mouseup', this.stopScrub);
    }

    componentWillUnmount() {
        document.removeEventListener('fullscreenchange', this.fullscreenListener);
        document.removeEventListener('mousemove', this.continueScrub);
        document.removeEventListener('mouseup', this.stopScrub);
        this.videoRef.current.removeEventListener('timeupdate', this.onVideoTimeUpdate);
        this.videoRef.current.removeEventListener('play', this.onVideoPlay);
        this.videoRef.current.removeEventListener('pause', this.onVideoPause);
        this.videoRef.current.removeEventListener('ended', this.onVideoEnd);
    }

    render() {
        const asset = ( <video ref={this.videoRef} onClick={this.changePlayStatus} className="video-asset" src={this.props.url}></video> );
        const wrapped = (
            <HoverCta icon={this.state.playing ? 'pause' : 'play'}>
                {asset}
            </HoverCta>);

        return (
            <figure className="SelfPortraitsVideo">
                <div className="wrapper cursor-hide" ref={this.wrapperRef}>
                    {wrapped}
                    { this.state.playing || this.state.fullscreen ? (
                        <div className="playback">
                            <div className="progress" ref={this.progressRef} onTouchStart={this.startTouchScrub} onTouchMove={this.continueTouchScrub}  onTouchEnd={this.stopTouchScrub} onMouseDown={this.startScrub}>
                                <div className="progress-bar">
                                    <div className="progress-bar-active" style={{transform: `scaleX(${(this.state.current / this.state.duration)})`}} ></div>
                                </div>
                            </div>
                            <div className="counter">
                                <p>{ this.convertToMMSS(this.state.current) }</p>
                                <p>/</p>
                                <p>{ this.convertToMMSS(this.state.duration) }</p>
                            </div>
                            <div className="fullscreen" onClick={this.toggleFullscreen}>
                                <img src={fullscreen} className="fullscreen-icon" alt="Enter Fullscreen" />
                            </div>
                        </div>
                    ) : null}
                    { this.state.fullscreen ? (
                        <button type="button" className="fullscreen-close" onClick={this.toggleFullscreen}>
                            <CloseIcon className="close-icon" alt="Close Icon" />
                        </button>
                    ) : null }
                </div>
            </figure>
        )
    }
}

export default SelfPortraitsVideo;
