import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './CurrentShow.scss';

class CurrentShow extends Component {
    render() {

        const gotoCurrent = (e) => {
            e.preventDefault();
            const isHash = window.location.hostname === "sc-bucket-stage-web.s3-website.eu-west-2.amazonaws.com";
            window.location = window.location.origin + (isHash ? '#' : '' ) + '/' + Storyblok.currentShowName;
        }

        return Storyblok.currentShowName === Storyblok.site.active_show.name ? <div className="CurrentShow"></div> : (
            <div className="CurrentShow regular">
                <p className="Message">Viewing Summer Show {Storyblok.site.active_show.name}</p>
                <p className="link" onClick={gotoCurrent}>Click here to view current show.</p>
            </div>
        )   
    }
}

export default CurrentShow;