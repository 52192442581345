// Libraries
import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Holding.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import Text from '../../components/Modules/Text/Text';

class Holding extends Component {

    state = {
        holding: null,
        menu: null,
        ready: false,
        countdown: {
          days: '',
          hours: '',
          mins: '',
          secs: ''
        }
    }

    async componentDidMount() {
        
        Track.page( 'Holding', '/holding' );

        return Storyblok.fetchShowPage('holding')
            .then( (holding) => {
                this.setState({ holding });
                return Storyblok.fetchShowPage('menu')
            })
            .then( (menu) => {
                this.setState({ menu, ready: true });
            })
    }

    render() {
        if (!this.state.ready) {
            return (<div></div>);
        }

        const customStyling = {
            backgroundColor: this.state.holding.background_color,
            color: this.state.holding.text_color
        }

        if (this.state.holding.background_image && this.state.holding.background_image.filename) {
            customStyling.backgroundImage = `url(${this.state.holding.background_image.filename})`;
        }

        const social = this.state.menu && this.state.menu.social_media ? 
            this.state.menu.social_media.map( (value) => {
                return (
                    <a key={value.display_name} className="link" href={ value.url } target="_blank" rel="noopener noreferrer">
                        <img src={ value.icon ? value.icon.filename : '' } alt={ value.display_name} />
                    </a>
                )
            }) : null;

        const overlayStyles = {
            backgroundColor: this.state.holding.background_overlay_color 
        }

        const overlay = this.state.holding.background_overlay_color ? (
            <div className="overlay" style={overlayStyles}></div>
        ) : '';

        const portraitImage = this.state.holding.portrait_image && this.state.holding.portrait_image.filename ? (
          <img className="portrait" src={ this.state.holding.portrait_image.filename } alt="Coming Soon Holding Page"/>
        ) : '';

        const landscapeImage = this.state.holding.landscape_image && this.state.holding.landscape_image.filename ? (
          <img className="landscape" src={ this.state.holding.landscape_image.filename } alt="Coming Soon Holding Page"/>
        ) : '';

        return (
            <Cursor>
                <div className="Holding theme-red" style={customStyling}>

                    <img className="logo" src={ this.state.holding.logo_image.filename } alt="National Saturday Club" />

                    { portraitImage }
                    { landscapeImage }
                    { overlay }

                    <Text className="copy" data={this.state.holding.copy[0]}></Text>

                    <div className="socials">
                        { social }
                    </div>
                </div>
            </Cursor>
        );
    }
}

export default Holding;