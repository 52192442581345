// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import { Helpers } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';
import Session from '../../classes/session';
import stickybits from 'stickybits'

// Styling
import './SelfPortraits.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import MasonryGallery from '../../components/MasonryGallery/MasonryGallery';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';
import SelfPortraitsModal from '../../components/SelfPortraitsModal/SelfPortraitsModal';

// Assets
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg';

class SelfPortraits extends BaseComponent {

    constructor(props) {
        super(props);
        this.infiniteScrollRef = React.createRef();
        this.clubSelectRef = React.createRef();
        this.colorRedRef = React.createRef();
        this.colorGreenRef = React.createRef();
        this.colorBlueRef = React.createRef();
    }

    state = {
        animateInState: true,
        club: '',
        clubs: [],
        images: [],
        page: 0,
        color: {
          red: 0,
          green: 0,
          blue: 0
        },
        loaded: false,
        modal: null,
        content: null
    }

    clubs = [];
    itemsPerPage = 25;
    portraits = [];
    filtered = [];

    displayMoreImages = () => {
        const newPage = this.state.page + 1;
        const images = this.filtered ? this.filtered
            .slice(0, newPage * this.itemsPerPage) : [];
        this.setState({images, page: newPage});
    }

    updateClub = () => {
        const club = this.clubSelectRef.current.value;
        this.setState({club});
        setTimeout( () => this.resetFiltered());
    }
    
    showModal(portrait, card) {
        const selected = {
            subname: card.subname,
            name: card.name,
            clubId: portrait.club_uuid,
            hasVideo: portrait.filename_video ? true : false,
            asset: portrait.filename_video ? portrait.filename_video : portrait.filename
        };
        this.setState({modal: selected});
    }

    viewPage = async (id) => {
        const slug = await Storyblok.clubSlugFromId( id )
        this.props.history.push(`/${this.activeShowName}/club/${slug}`);
    }

    closeModalHandler = () => {
        this.setState({modal: null});
    }

    resetFiltered() {

        this.filtered = this.portraits
            .filter( (item) => (!this.state.club || this.state.club === item.image.club_uuid ) );

        if (this.state.color.red + this.state.color.green + this.state.color.blue > 0) {

            this.filtered = this.filtered.filter( (item) => item.image.lab );

            const testLab = Helpers.rgb2lab( this.state.color.red, this.state.color.green, this.state.color.blue );

            const diff = (lab) => {
                return Math.sqrt(Math.pow(testLab[0] - lab[0], 2) + Math.pow(testLab[1] - lab[1], 2) + Math.pow(testLab[2] - lab[2], 2)) / 2.55
            }

            this.filtered.forEach( (item) => {
                item.image._lab = item.image.lab ? diff( item.image.lab ) : null;
            });
       
            this.filtered.sort( (a,b) => a.image._lab && b.image._lab ? a.image._lab - b.image._lab : 1 );

        }

        this.filtered.forEach( (item, index) => {
            item.image._index = index
        });

        this.infiniteScrollRef.current.resetScroll();
        this.setState({page: 0, images: []}, this.displayMoreImages );

    }

    updateColor = () => {
        clearTimeout(this.throttle);
        const color = {
            red: this.colorRedRef ? this.colorRedRef.current.value : 0,
            green: this.colorGreenRef ? this.colorGreenRef.current.value : 0,
            blue: this.colorBlueRef ? this.colorBlueRef.current.value : 0
        }
        this.setState({ color });

        document.documentElement.style
            .setProperty('--portraits-current', `rgb(${color.red}, ${color.green}, ${color.blue})`);
        document.documentElement.style
            .setProperty('--portraits-red-left', `rgb(0, ${color.green}, ${color.blue})`);
        document.documentElement.style
            .setProperty('--portraits-red-right', `rgb(255, ${color.green}, ${color.blue})`);
        document.documentElement.style
            .setProperty('--portraits-green-left', `rgb(${color.red}, 0, ${color.blue})`);
        document.documentElement.style
            .setProperty('--portraits-green-right', `rgb(${color.red}, 255, ${color.blue})`);
        document.documentElement.style
            .setProperty('--portraits-blue-left', `rgb(${color.red}, ${color.green}, 0)`);
        document.documentElement.style
            .setProperty('--portraits-blue-right', `rgb(${color.red}, ${color.green}, 255)`);

        this.throttle = setTimeout( () => this.setState({page: 0, images: []}, this.resetFiltered ), 250);
    }

    getData() {
        Storyblok.fetchShowClubs()
            .then( (clubs) => this.clubs = clubs )
            .then( () => Storyblok.fetchShowSelfPortraits() )
            .then( (portraits) => {
                const clubsWithGallery = portraits ? portraits.map( (portrait) => portrait.club_uuid) : [];
                const clubs = this.clubs.filter( (club) => clubsWithGallery.includes(club.uuid) );
                this.setState({clubs});
                this.portraits = (portraits ? portraits.map( (portrait) => {
                    const club = this.state.clubs.find( (club) => club.uuid === portrait.club_uuid );

                    if (!club) {
                        return null;
                    }

                    const card = {
                        name: club.shortName,
                        subname: club.category && club.category.display_name ? club.category.display_name : null
                    }
                    return {image: portrait, card, callback: () => this.showModal(portrait, card)};
                }) : []).filter( item => item /* removed empty ones */)

                return this.portraits;
            })
            .then( (portraits) => this.filtered = portraits )
            .then( () => this.displayMoreImages() )
            .then( () => Storyblok.fetchShowPage( 'self-portraits') )
            .then( ( content ) => this.setState({loaded: true, animateInState: false, content }));
    }

    componentDidMount() {
        this.getData();
        Track.page( 'Self Portraits', '/self-portraits' );
        stickybits('.sticky');
    }

    render() {
        const mainClasses = this.state.animateInState ? 'SelfPortraits animate-page-in-state' : 'SelfPortraits';
        const dropdownClasses = this.state.club ? 'selector active' : 'selector';
        const selectClasses = this.state.club ? 'select regular bold' : 'select regular bold hidden';
        const details = this.state.content ? this.state.content.copy_details : '';

        const colorSelected = {
            backgroundColor: `rgb(${this.state.color.red}, ${this.state.color.green}, ${this.state.color.blue})`
        };
        return (
            <Cursor>
                { this.state.loaded ? null : <Loader /> }
                <div className={mainClasses}>
                    <InfiniteScroll ref={this.infiniteScrollRef} getNewContent={this.displayMoreImages}>
                    
                        <div className="section-header">
                            <div className="Text left">
                                <div className="text-content">
                                    <h1 className="xxl bold red">Expressions of Self</h1>
                                </div>
                            </div>
                            <div className="Text">
                                <div className="text-content">
                                    <p className="regular bold push-down">{details}</p>
                                </div>
                            </div>
                        </div>
                        <div className="options sticky">
                            <div className="options-content">
                                <div className="filters">
                                    <div className={dropdownClasses}>
                                        { !Session.isIE && ( 
                                            <label htmlFor="select-club" className="label red">
                                                <p className="regular bold">Select Club</p>
                                            </label>
                                        )}
                                        <select ref={this.clubSelectRef} id="select-club" className={selectClasses} defaultValue="" onChange={this.updateClub}>
                                            <option value="">All Clubs</option>
                                            { this.state.clubs.map( (club) => (
                                                <option key={club.uuid} value={club.uuid}>{ club.longName }</option>
                                            )) }
                                        </select>
                                        <ArrowDown className="dropdown-icon" alt="Down Icon" />
                                    </div>
                                </div>
                                <div className="colors">
                                    <label className="colors-label regular semi-bold">Sort by colour</label>
                                    <div className="colors-selector">
                                        <div className="colors-select">
                                            <label className="regular semi-bold">R</label>
                                            <input ref={this.colorRedRef} type="range" className="colors-select-red" min="0" max="255" onChange={this.updateColor} id="red" name="red" value={this.state.color.red}/>
                                        </div>
                                        <div className="colors-select">
                                            <label className="regular semi-bold">G</label>
                                            <input ref={this.colorGreenRef} type="range" className="colors-select-green" min="0" max="255" onChange={this.updateColor} id="green" name="green" value={this.state.color.green} />
                                        </div>
                                        <div className="colors-select">
                                            <label className="regular semi-bold">B</label>
                                            <input ref={this.colorBlueRef} type="range" className="colors-select-blue" min="0" max="255" onChange={this.updateColor} id="blue" name="blue" value={this.state.color.blue} />
                                        </div>
                                    </div>
                                    <div className="colors-result" style={colorSelected}></div>
                                </div>
                            </div>
                        </div>
                        { this.state.loaded ? (<MasonryGallery className="masonry" items={this.state.images} preventHorizontal={true} showExpandCursor={true} />) : null }
                    </InfiniteScroll>
                    

                    { this.state.modal ? (
                        <SelfPortraitsModal onCloseHandler={this.closeModalHandler} viewPageHandler={this.viewPage} data={this.state.modal} />
                    ) : '' }

                </div>
            </Cursor>
        );
    }
}

export default SelfPortraits;