// Libraries
import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Messages from '../../classes/messages';

// Styling
import './SubmitMessage.scss';

// Components
import Loader from '../Loader/Loader';

// Emojis
import Clap from '../../assets/images/emojis/clap.png';
import Fire from '../../assets/images/emojis/fire.png';
import HeartEyes from '../../assets/images/emojis/heart-eyes.png';
import Heart from '../../assets/images/emojis/heart.png';
import Smiley from '../../assets/images/emojis/smiley.png';
import Star from '../../assets/images/emojis/star.png';
import ThumbsUp from '../../assets/images/emojis/thumbs-up.png';

class SubmitMessage extends Component {

    constructor(props) {
        super(props);
        this.firstElementRef = React.createRef();
    }

    state = {
        animateInState: true,
        animateOutState: false,
        errorMessage: null,
        loading: false,
        form: {
            name: '',
            content: '',
            emoji: '',
            location: '',
            show: Storyblok.site.active_show.name
        },
        submitted: false
    }

    timer = null;

    handleFormChange = (event) => {
        let value = event.target.value;
        if (event.target.name === 'content' && value.length > 100) {
            value = value.substring(0, 100);
        }

        const formData = Object.assign({}, this.state.form);
        formData[event.target.name] = value;
        this.setState({form: formData});
    }

    checkFormValid(data) {
        return data.name.trim() && data.content.trim() && data.emoji && data.location.trim() ? true : false;
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        if ( this.checkFormValid(this.state.form) ) {
            this.setState({loading: true});
            Messages.send(this.state.form)
                .then( () => {
                    this.setState({loading: false, submitted: true});
                })
                .catch( (err) => this.setState({errorMessage: err, loading: false}));
        } else {
            this.setState({errorMessage: 'All form fields are required'});
        }
    }

    closeHandler = (event) => {
        event.preventDefault();
        this.setState({animateOutState: true});
        this.timer = setTimeout( () => this.props.onCloseHandler(), 750);
    }

    componentDidMount() {
        this.timer = setTimeout( () => this.setState({animateInState: false}) );
        this.firstElementRef.current.focus();
    }
    
    render() {
        const emojis = ['star', 'heartEyes', 'smiley', 'thumbsUp', 'clap', 'fire',  'heart'];

        const getImage = (id) => {
            switch (id) {
                case 'clap':
                    return (<img className="emoji-icon" src={Clap} alt="Clap Emoji" />)
                case 'fire': 
                    return (<img className="emoji-icon" src={Fire} alt="Fire Emoji" />)
                case 'heartEyes': 
                    return (<img className="emoji-icon" src={HeartEyes} alt="Heart Eyes Emoji" />)
                case 'heart': 
                    return (<img className="emoji-icon" src={Heart} alt="Heart Emoji" />)
                case 'smiley': 
                    return (<img className="emoji-icon" src={Smiley} alt="Smiley Emoji" />)
                case 'star': 
                    return (<img className="emoji-icon" src={Star} alt="Star Emoji" />)
                case 'thumbsUp': 
                    return (<img className="emoji-icon" src={ThumbsUp} alt="Heart Eyes Emoji" />)
                default:
                    return (<img className="emoji-icon" src={Clap} alt="Clap Emoji" />)
            }
        }

        const classes = ["SubmitMessage"];
        if (this.state.animateInState) {
            classes.push("animate-element-in-state")
        }
        if (this.state.animateOutState) {
            classes.push("animate-element-out-state")
        }

        const outerClose = (evt) => {
            if (evt.target && evt.target.classList.contains( 'SubmitMessage' ) ) {
                this.closeHandler(evt);
            }
        }

        return (
            <div className={classes.join(" ")} onClick={ outerClose }>
                {this.state.loading ? (<Loader />) : (
                    !this.state.submitted ? (
                        <div className="submit-content">
                            <h1 className="submit-title red bold">Write your message</h1>
                            <p className="submit-text black">Please complete the form below. Each message will be moderated before appearing on the site.</p>
                            <form className="form" onSubmit={this.onSubmitHandler}>
                                { this.state.errorMessage ? (
                                    <p className="regular red bold">{this.state.errorMessage}</p>
                                ) : null}
                                <div className="form-element">
                                    <label className="form-label grey">First Name</label>
                                    <input ref={this.firstElementRef} type="text" className="form-input" name="name" value={this.state.form.name} onChange={this.handleFormChange} />
                                </div>
                                <div className="form-element">
                                    <label className="form-label grey">Location (Town/City)</label>
                                    <input type="text" className="form-input" name="location" value={this.state.form.location} onChange={this.handleFormChange} />
                                </div>
                                <div className="form-element">
                                    <div className="row">
                                        <label className="form-label grey">Your Message</label>
                                        <p className="form-label grey">{this.state.form.content.length}/100</p>
                                    </div>
                                    <textarea type="text" className="form-input" name="content" rows="4" placeholder="Placeholder Text" value={this.state.form.content} onChange={this.handleFormChange} />
                                </div>
                                <div className="form-element">
                                    <label className="form-label grey">Select Emoji</label>
                                    <div className="form-emojis">
                                        { emojis.map( (emoji) => (
                                            <div className="form-emoji" key={emoji}>
                                                <input id={`emoji-${emoji}`} type="radio" className="form-emoji-input" name="emoji" checked={this.state.form.emoji === emoji} value={emoji} onChange={this.handleFormChange} />
                                                <label htmlFor={`emoji-${emoji}`} className="form-emoji-label">{getImage(emoji)}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-options">
                                    <button type="submit" className="button bold">Submit message</button>
                                    <button type="button" className="cancel bold red" onClick={this.closeHandler}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="submit-content">
                            <h1 className="submit-title red bold">Thank You</h1>
                            <p className="submit-text black"><br/>All messages will be moderated before appearing in the Guestbook.<br/><br/>Please check back later to view your message.</p>
                            <button type="button" className="button bold" onClick={this.closeHandler}>Close</button>
                        </div>
                    )
                )}
            </div>
        );
    }
}

export default SubmitMessage;