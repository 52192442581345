// Libraries
import { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

class Hotjar extends Component {
    componentDidMount() {

        const showHotjar = (!Storyblok.isPortraits && Storyblok.site.show_hotjar === true && Storyblok.site.active_show.name === Storyblok.currentShowName) ||
          (Storyblok.isPortraits && Storyblok.site.self_portraits_show_hotjar === true && Storyblok.site.active_show.name === Storyblok.currentShowName)

        if (showHotjar) {
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2396883,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }
    }

    render() {
        return '';
    }
}

export default Hotjar;