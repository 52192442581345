// Libraries
import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Switch, Route } from "react-router-dom";
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './App.scss';

// Containers
import AboutUs from '../AboutUs/AboutUs';
import Archive from '../Archive/Archive';
import Club from '../Club/Club';
import Home from '../Home/Home';
import HomePortraits from '../HomePortraits/HomePortraits';
import Masterclass from '../Masterclass/Masterclass';
import Masterclasses from '../Masterclasses/Masterclasses';
import Members from '../Members/Members';
import MessageWall from '../MessageWall/MessageWall';
import Partners from '../Partners/Partners';
import Search from '../Search/Search';
import SelfPortraits from '../SelfPortraits/SelfPortraits';
import Holding from '../Holding/Holding';
import Launch from '../Launch/Launch';
import UserPage from '../UserPage/UserPage';

// Components
import Header from '../../components/Header/Header';
import CookieBar from '../../components/CookieBar/CookieBar';
import Showcase from '../../components/Showcase/Showcase';
import Session from '../../classes/session';
import Hotjar from '../../components/Hotjar/hotjar';

class App extends Component {

    state = {
        initComplete: false,
        showLaunch: false,
        menu: null
    }

    componentDidMount() {
        const path = window.location.hostname === "sc-bucket-stage-web.s3-website.eu-west-2.amazonaws.com" ? window.location.hash : window.location.pathname;
        if (path.replace('#', '') !== '/') {
            Session.launchAnimationPlayed = true;
        }

        Storyblok.initialise()
            .then( () => Storyblok.fetchShowPage( 'menu' )
            .then( (menu) => {
                this.setState({menu});
            }))
            .then( () => this.setState({
                initComplete: true,
                showLaunch: Storyblok.showLaunchVideo
            }), (err) => console.log('Error', err) );
    }

    render() {

        if (!this.state.initComplete) {
            return null;
        }
        
        const site = (
            <Switch>
                <Route exact path="/:year?" render={ (props) =>
                  Storyblok.isPortraits ? <HomePortraits {...props} /> : <Home {...props} /> 
                }/>
                <Route exact path="/launch" component={Launch} />
                <Route exact path="/:year?/members" component={Members} />
                <Route exact path="/:year?/search" component={Search} />
                <Route exact path="/:year?/self-portraits" component={SelfPortraits} />
                <Route exact path="/:year?/club/:id" component={Club} />
                <Route exact path="/:year?/about-us" component={AboutUs} />
                <Route exact path="/:year?/partners" component={Partners} />
                <Route exact path="/:year?/masterclasses" component={Masterclasses} />
                <Route exact path="/:year?/masterclass/:id" component={Masterclass} />
                <Route exact path="/:year?/guestbook" component={MessageWall} />
                <Route exact path="/archive" component={Archive} />

                <Route exact path="/:year?/:page_id" render={ (props) => 
                    <UserPage {...props} page_id={ props.match.params.page_id } key={ props.match.params.page_id } />
                }/>
                
                
            </Switch>
        );
    
        const hashRouter = (
            <HashRouter>
                <Hotjar />
                <Header />
                {site}
                <Showcase />
                <CookieBar />
            </HashRouter>
        );

        const browserRouter = (
            <BrowserRouter>
                <Hotjar />
                <Header />
                {site}
                <Showcase />
                <CookieBar />
            </BrowserRouter>
        );
        
        const router = window.location.hostname === "sc-bucket-stage-web.s3-website.eu-west-2.amazonaws.com" ? hashRouter : browserRouter;

        const holdingSite = (
            <Holding></Holding>
        )

        const skipHolding = window.location.search.indexOf( '_skip_holding_' ) > 0;

        // Make sure the correct show is loaded at app level (other components will handle the show themselves via router)
        // bit we can't do that here as the route has not been resolved yet
        const showName = window.location.pathname
            .split( /[\\/]/ )        /* split on backslash or forward slash */
            .filter( item => item ) /* remove empty elements */
            [0] || ''; /* extract first part of path */

        if (showName) {
            Storyblok.showName = showName
        }

        return this.state.initComplete ? (!skipHolding && Storyblok.showHolding > 0 ? holdingSite : router) : null;
    }
}

export default App;

