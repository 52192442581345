import { Storyblok } from '@imagination/sc-storyblok-lib-js';

class Messages {
    static getAll() {
        return new Promise( (resolve, reject) => {
            const showName = Storyblok.site.active_show.name;
            const requestLimit = '500';
            const url = `https://96u5gnm3ta.execute-api.us-east-1.amazonaws.com/live/messages?ShowName=${showName}&PageLimit=${requestLimit}`;
            fetch( url, {
                method: 'GET'
            } )
                .then( (response) => response.json() )
                .then( (response) => resolve(response.Items) )
                .catch( (err) => reject(err) )
        });
    }

    static send(data) {
        return new Promise( (resolve, reject) => {
            const url = 'https://96u5gnm3ta.execute-api.us-east-1.amazonaws.com/live/messages';
            fetch( url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            } )
                .then( (response) => response.json() )
                .then( (response) => resolve(response) )
                .catch( (err) => reject(err) )
        });
    }
}

export default Messages;