// Libraries
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './Menu.scss';

// Components
import Cursor from '../../Cursor/Cursor';

// Assets
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

class Menu extends Component {

    state = {
        animateClass: true,
        content: null,
        showArchive: Object.values(Storyblok.shows).filter( (show) => show.enabled).length > 1
    }
    timer = null;

    closeHandler = () => {
        this.setState({animateClass: true});
        this.timer = setTimeout( () => {
            this.setState({animateClass: false});
            this.props.close();
        }, 375);
    }

    componentDidMount() {
        Storyblok.fetchPage('menu')
            .then( (menu) => {
                this.setState( {content: menu} );
                this.timer = setTimeout( () => this.setState({animateClass: false}));
            });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        if (!this.state.content) {
            return '';
        }

        const loadArchive = (e) => {
            e.preventDefault();
            const showName = e.currentTarget.attributes.getNamedItem( 'data-show-name' ).value;
            const isHash = window.location.hostname === "sc-bucket-stage-web.s3-website.eu-west-2.amazonaws.com";
            window.location = window.location.origin + (isHash ? '#' : '' ) + '/' + showName;
        }

        const classes = this.state.animateClass ? 'Menu start' : 'Menu';
        const logo = Storyblok.site.logo_light;
        const showName = Storyblok.site.active_show.name;
        
        const archiveShowsList = Object.values(Storyblok.shows)
            .filter( show => show.enabled && (show.name !== Storyblok.currentShowName && show.name !== showName) );

        const archiveShows = archiveShowsList.sort( (a,b) => a.name.localeCompare(b.name) )
            .map( show => (<Link to={`/${show.name}`} onClick={loadArchive} key={show.name} className="link" data-show-name={show.name}>{ show.name }</Link>) );

        if (showName !== Storyblok.currentShowName) {
            archiveShows.unshift( 
                <Link to={`/${Storyblok.currentShowName}`} onClick={loadArchive} key={Storyblok.currentShowName} className="link" data-show-name={Storyblok.currentShowName}>Current Show</Link>
            )
        }

        const padIndex = (index) => `0${index}`.substr(-2);

        const menu_items = this.state.content.menu_items.map( (item, index) =>
            <li className="item" key={item.path || 'home'}>
                <p className="number">{ padIndex(index + 1) }.</p>
                <Link to={`/${showName}${item.path ? '/' + item.path : '' }`} className="link" onClick={this.closeHandler}>{ item.label }</Link>
            </li> 
        );

        const navClasses = 'navigation' + (menu_items.length >= 10 ? ' small-items' : '');

        const menu = this.state.content ? (
            <nav className={ navClasses }>
                <ul className={"list"}>
                   
                    { menu_items }
                    
                    { 
                        this.state.content.show_archive === true && archiveShows.length > 0 ?
                            <li className="item archive">
                                <p className="link no-hover archive">{ this.state.content.copy_archive }</p>
                            </li> : '' 
                    }

                    { 
                        this.state.content.show_archive === true && archiveShows.length > 0 ?
                            <div className="shows">{ archiveShows }</div> : '' 
                    }
                    
                </ul>
            </nav>
        ) : null;

        const social = this.state.content && this.state.content.social_media ? 
            this.state.content.social_media.map( (value) => {
                return (
                    <li key={value.display_name} className="item">
                        <a className="link" href={ value.url } target="_blank" rel="noopener noreferrer">{ value.display_name }</a>
                    </li>
                )
            }) : null;

        const address = this.state.content ? (
            <div className="address">
                <h3 className="heading">{ this.state.content.copy_contact_us }</h3>
                <p className="line">{ this.state.content.address }</p>
                <p className="line">{ this.state.content.telephone }</p>
                <p className="line">{ this.state.content.email }</p>
            </div>
        ) : null;

        const support = this.state.content ? (
            <div className="support">
                <img className="image" src={this.state.content.logos_primary.filename} alt={this.state.content.logos_image_description} />
            </div>
        ) : null;

        const copyright = this.state.content ? (
            <p className="copyright" dangerouslySetInnerHTML={{ __html: this.state.content.copy_copyright}}></p>
        ) : null;

        return (
            <section className={classes}>
                <Cursor>
                    <div className="content">
                        <img className="menu-logo" src={logo.filename} alt={logo.alt} />
                        
                        <button type="button" className="close" onClick={this.closeHandler}>
                            <CloseIcon className="close-icon" alt="Close Icon" />
                        </button>

                        <div className="column column-left">
                            {menu}
                        </div>

                        <div className="column column-right">
                            <div className="contact">
                                <nav className="social">
                                    <ul className="list">
                                        {social}
                                    </ul>
                                </nav>
                                {address}
                            </div>
                            {support}
                            {copyright}
                        </div>
                    </div>
                </Cursor>
            </section>
        )
    }
}

export default Menu;