import React from 'react';
import './LayoutRow.scss';
import ModuleBlock from '../ModuleBlock/ModuleBlock';
import Text from '../Text/Text';

const LayoutRow = (props) => {

    const styling = {
        justifyContent: props.data.justify ? props.data.justify : 'flex-start',
        alignItems: props.data.alignment ? props.data.alignment : 'flex-start'
    };
    let classes = props.data.theme ? `LayoutRow has-theme theme-${props.data.theme}` : 'LayoutRow';
    classes = props.data.background_text.length ? `${classes} has-bg-text` : classes;

    const backgroundText = props.data.background_text.length ? (
        <div className="background-text">
            <Text data={props.data.background_text[0]} />
        </div>
    ) : null;

    return (
        <div className={classes} style={styling}>
            {backgroundText}
            { props.data.items ? props.data.items.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : null }
        </div>
    )
}

export default LayoutRow;