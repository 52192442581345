import React, { Component } from 'react';
import Player from '@vimeo/player';

// Components
import AnimateElementIn from '../../AnimateIn/AnimateElementIn/AnimateElementIn';
import HoverCta from '../../HoverCta/HoverCta';
import Text from '../Text/Text';

// Styling
import './Video.scss';

// Assets
import fullscreen from '../../../assets/images/fullscreen.png';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

class Video extends Component {

    constructor(props) {
        super(props);
        this.progressRef = React.createRef();
        this.wrapperRef = React.createRef();
    }

    state = {
        loaded: false,
        playing: false,
        duration: null,
        current: null,
        fullscreen: false
    };

    sizes = {
        mobile: {
            'x-small': 90,
            'small': 90,
            'medium': 90,
            'large': 90,
            'x-large': 90
        },
        desktop: {
            'x-small': 15,
            'small': 28,
            'medium': 43,
            'large': 60,
            'x-large': 75
        }
    }

    player = null;
    currentlyTouch = false;
    scrubbing = false;

    defineSizeByHeight = () => {
        const video = document.getElementById( `video_${this.props.data._uid}` );
        const iframe = video.children[0];
        const heightInVH = this.props.data.size ? this.sizes.desktop[this.props.data.size] : this.sizes.desktop['medium'];
        const heightInPX = Math.round((window.innerHeight / 100) * heightInVH);
        const widthInPX = Math.round((heightInPX / iframe.height) * iframe.width);
        iframe.style.height = `${heightInPX}px`;
        iframe.style.width = `${widthInPX}px`;
    }

    defineSizeByWidth = () => {
        const video = document.getElementById( `video_${this.props.data._uid}` );
        const iframe = video.children[0];
        const widthInVW = this.props.data.size ? this.sizes.mobile[this.props.data.size] : this.sizes.mobile['medium'];
        const widthInPX = Math.round((window.innerWidth / 100) * widthInVW);
        const heightInPX = Math.round((widthInPX / iframe.width) * iframe.height);
        iframe.style.height = `${heightInPX}px`;
        iframe.style.width = `${widthInPX}px`;
    }

    // Resize iframe to be the correct size
    resizePlayer = () => {
        if (this.state.loaded) {
            if(window.innerWidth > window.innerHeight) {
                this.defineSizeByHeight();
            } else {
                this.defineSizeByWidth();
            }
        }
    }

    setNewTime(time) {
        if (time !== null) {
            this.setState({current: time});
            this.player.setCurrentTime(time);
        }
    }

    findNewTime = (event) => {
        if (this.state.duration) {
            const rect = this.progressRef.current.getBoundingClientRect();
            const percentX = (event.clientX - rect.left) / rect.width;
            const seconds = this.state.duration * percentX;
            return seconds < 0 || seconds > this.state.duration ? null : seconds;
        } else {
            return null;
        }
    }

    startTouchScrub = (event) => {
        if (event.targetTouches) {
            this.scrubbing = true;
            this.currentlyTouch = true;
            const time = this.findNewTime(event.targetTouches[0]);
            this.setNewTime(time);
        }
    }

    continueTouchScrub = (event) => {
        if (event.targetTouches && this.scrubbing && !this.settingNewTime) {
            const time = this.findNewTime(event.targetTouches[0]);
            this.setNewTime(time);
        }
    }

    stopTouchScrub = (event) => {
        this.scrubbing = false;
    }

    startScrub = (event) => {
        if (!this.currentlyTouch) {
            this.scrubbing = true;
            const time = this.findNewTime(event);
            this.setNewTime(time);
        }
    }

    continueScrub = (event) => {
        if (!this.currentlyTouch && this.scrubbing && !this.settingNewTime) {
            const time = this.findNewTime(event);
            this.setNewTime(time);
        }
    }

    stopScrub = () => {
        this.scrubbing = false;
        this.currentlyTouch = false;
    }

    fullscreenListener = () => {
        const fullscreen = document.fullscreenElement ? true : false;
        this.setState({fullscreen});
    }

    toggleFullscreen = (event) => {
        event.stopPropagation();
        const fullscreen = this.state.fullscreen;
        if (fullscreen) {
            document.exitFullscreen();
        } else {
            if (this.wrapperRef.current.requestFullscreen) {
                this.wrapperRef.current.requestFullscreen();
            }
        }
    }

    onVideoTimeUpdate = (event) => {
        this.setState({duration: event.duration, current: event.seconds});
    }

    onVideoPlay = () => {
        this.setState({playing: true});
    }

    onVideoPause = () => {
        this.setState({playing: false});
    }

    onVideoEnd = () => {
        this.player.setCurrentTime(0);
        if (this.props.finished) {
            this.props.finished();
        }
        this.setState({playing: false});
    }

    onVideoLoaded = (event) => {
        this.setState({loaded: true});
        this.resizePlayer();
    }

    changePlayStatus = () => {
        if (this.player) {
            if (this.state.playing) {
                this.player.pause();
            } else {
                this.player.play();
            }
        }
    }

    convertToMMSS(time) {
        const rounded = Math.round(time);
        const minutes = Math.floor(rounded / 60);
        const seconds = Math.floor(rounded % 60);
        return `${('0' + (minutes)).substr(-2)}:${('0' + (seconds)).substr(-2)}`;
    }

    setupVideo() {
        this.player = new Player(`video_${this.props.data._uid}`, {
            id: this.props.data.source[0].vimeo_id,
            width: 640,
            controls: false,
            playsinline: false,
            autoplay: this.props.autoPlay ? true : false
        });

        this.player.setVolume(1);
        this.player.on('loaded', this.onVideoLoaded);
        this.player.on('timeupdate', this.onVideoTimeUpdate);
        this.player.on('play', this.onVideoPlay);
        this.player.on('pause', this.onVideoPause);
        this.player.on('ended', this.onVideoEnd);
    }

    componentDidMount() {
        this.setupVideo();
        window.addEventListener('resize', this.resizePlayer);
        document.addEventListener('fullscreenchange', this.fullscreenListener);
        document.addEventListener('mousemove', this.continueScrub);
        document.addEventListener('mouseup', this.stopScrub);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePlayer);
        document.removeEventListener('fullscreenchange', this.fullscreenListener);
        document.removeEventListener('mousemove', this.continueScrub);
        document.removeEventListener('mouseup', this.stopScrub);
        this.player.off('loaded', this.onVideoLoaded);
        this.player.off('timeupdate', this.onVideoTimeUpdate);
        this.player.off('play', this.onVideoPlay);
        this.player.off('pause', this.onVideoPause);
        this.player.off('ended', this.onVideoEnd);
        this.player.destroy();
    }

    render() {
        
        const classes = this.props.data.adjacent_text_location ? `Video adjacent-position-${this.props.data.adjacent_text_location}` : `Video`;

        const size = this.props.data.size ? `asset-${this.props.data.size}` : null;        
        const asset = ( <div onClick={this.changePlayStatus} className={['asset', size].join(' ')} id={`video_${this.props.data._uid}` }></div> );
        const wrapped = (
            <HoverCta icon={this.state.playing ? 'pause' : 'play'}>
                {asset}
            </HoverCta>);

        // Caption 
        const caption = this.props.data.adjacent_text.length && this.props.data.adjacent_text[0].copy.markup ? (
            <figcaption className="adjacent">
                <Text data={this.props.data.adjacent_text[0]}></Text>
            </figcaption>
        ) : null;

        return (
            <AnimateElementIn>
                <figure className={classes}>
                    <div className="wrapper" ref={this.wrapperRef}>
                        {wrapped}
                        { this.state.playing || this.state.fullscreen ? (
                            <div className="playback">
                                <div className="progress" ref={this.progressRef} onTouchStart={this.startTouchScrub} onTouchMove={this.continueTouchScrub}  onTouchEnd={this.stopTouchScrub} onMouseDown={this.startScrub}>
                                    <div className="progress-bar">
                                        <div className="progress-bar-active" style={{transform: `scaleX(${(this.state.current / this.state.duration)})`}} ></div>
                                    </div>
                                </div>
                                <div className="counter">
                                    <p>{ this.convertToMMSS(this.state.current) }</p>
                                    <p>/</p>
                                    <p>{ this.convertToMMSS(this.state.duration) }</p>
                                </div>
                                <div className="fullscreen" onClick={this.toggleFullscreen}>
                                    <img src={fullscreen} className="fullscreen-icon" alt="Enter Fullscreen" />
                                </div>
                            </div>
                        ) : null}
                        { this.state.fullscreen ? (
                            <button type="button" className="fullscreen-close" onClick={this.toggleFullscreen}>
                                <CloseIcon className="close-icon" alt="Close Icon" />
                            </button>
                        ) : null }
                    </div>
                    {caption}
                </figure>
            </AnimateElementIn>
        )
    }
}

export default Video;
