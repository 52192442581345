// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './AboutUs.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import Loader from '../../components/Loader/Loader';
import ModuleBlock from '../../components/Modules/ModuleBlock/ModuleBlock';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class AboutUs extends BaseComponent {

    state = {
        structure: null
    }

    getData() {
        Storyblok.fetchShowPage('about-us').then( (about) => {
            this.setState({structure: about.structure});
        });
    }

    componentDidMount() {
        Track.page( 'About', '/about' );
        this.getData();
    }

    render() {
        return (
            <Cursor>
                <ScrollDirection>
                    <div className="AboutUs">
                        { this.state.structure ? this.state.structure.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : <Loader /> }
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default AboutUs;