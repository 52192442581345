// Libraries
import React from 'react';

// Styling
import './AddressListSelector.scss';

const AddressListSelector = (props) => {

    const jumpToSection = (letter) => {
        var element = document.getElementById(letter);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    }

    let classes = props.horizontal ? "AddressListSelector horizontal" : "AddressListSelector";
    classes += (props.tutors === true ) ? " tutors" : "";

    return ( 
        <div className={classes}>
            { props.list.map( (group) => (
                <span key={group.name} className="nav-item" onClick={() => jumpToSection(group.name)}>{group.name}</span>
            ) ) }
        </div>
    )
}

export default AddressListSelector;