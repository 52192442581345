// Libraries
import React, { Component } from 'react';

// Styling
import './SelfPortraitsModal.scss';

// Components
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import SelfPortraitsVideo from '../SelfPortraitsVideo/SelfPortraitsVideo';

import { Storyblok } from '@imagination/sc-storyblok-lib-js';

class SelfPortraitsModal extends Component {

    state = {
        animateInState: true,
        animateOutState: false
    }

    timer = null;

    viewHandler = (id) => {
        this.props.viewPageHandler(id);
    }

    closeHandler = (event) => {
        event.preventDefault();
        console.log('here??')
        this.setState({animateOutState: true});
        this.timer = setTimeout( () => this.props.onCloseHandler(), 750);
    }

    componentDidMount() {
        this.timer = setTimeout( () => this.setState({animateInState: false}) );
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        clearTimeout(this.assetTimer);
    }
    
    render() {
        const classes = ["SelfPortraitsModal"];
        if (this.state.animateInState) {
            classes.push("animate-element-in-state")
        }
        if (this.state.animateOutState) {
            classes.push("animate-element-out-state")
        }

        const link = Storyblok.isPortraits ? 
          (<p className="medium nolink bold black">{this.props.data.name}</p>)
          :
          (<p onClick={() => this.viewHandler(this.props.data.clubId)} className="medium bold black push-down">{this.props.data.name}</p>)

        return (
            <div className={classes.join(" ")}>
                <div className="modal-content">
                    { this.props.data.hasVideo ? (
                        <SelfPortraitsVideo url={this.props.data.asset} />
                    ) : ( 
                        <img className="asset" alt="Expanded Portrait" src={this.props.data.asset} />
                    )}
                    <div className="description">
                        <p className="small bold grey">{this.props.data.subname}</p>
                        { link }
                        <button type="button" className="close" onClick={this.closeHandler}>
                            <CloseIcon className="close-icon" alt="Close Icon" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SelfPortraitsModal;