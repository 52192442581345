// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './UserPage.scss';

import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import ModuleBlock from '../../components/Modules/ModuleBlock/ModuleBlock';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class UserPage extends BaseComponent {

    state = {
        structure: null,
        tracking_name: ''
    }

    getData() {

        const getTrackingName = (id) => id.replace( /[_-]/g, ' ')
            .split( ' ')
            .map( word => (word[0] || '').toUpperCase() + word.substr(1) )
            .join( ' ' );

        Storyblok.fetchShowPage( this.props.page_id )
            .then( (visit) => {
                this.setState({
                    structure: visit.structure,
                    tracking_name: getTrackingName( this.props.page_id )
                });
            })
            .catch( err => this.props.history.push( `/${Storyblok.site.active_show.name}` ) );
    }

    componentDidMount() {

        Track.page( this.props.tracking_name, `/${this.props.page_id}` );

        this.getData();

    }

    render() {
        return (
            <Cursor>
                <ScrollDirection>
                    <div className="UserPage">
                        { this.state.structure ? this.state.structure.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : <Loader /> }
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default UserPage;