import React from 'react';
import AnimateElementIn from '../../AnimateIn/AnimateElementIn/AnimateElementIn';

import './Text.scss';

const Text = (props) => {

    const convertToHTML = (copy) => {
        const regExp = /\[([^\]]+)\]/gi;
        return copy.replace(regExp, (match) => {
            if (match.startsWith('[/')) {
                return '</span>';
            } else {
                //eslint-disable-next-line
                return `<span class="${ match.replace(/[\[\]]+/g, '')}">`;
            }
        });
    }

    const content = props.data.copy ? convertToHTML(`${props.data.copy.markup}`) : null;
    const classes = props.data.control_line_breaks ? 'Text' : 'Text set-width-bounds';

    return (
        <AnimateElementIn>
            <div className={classes}>
                <style>{props.data.copy.custom_css}</style>
                <p className="text-content" dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
        </AnimateElementIn>
    )
}

export default Text;