import React from 'react';
import './Image.scss';

// Components
import HoverCta from '../../HoverCta/HoverCta';
import Text from '../Text/Text';
import AnimateElementIn from '../../AnimateIn/AnimateElementIn/AnimateElementIn';
import Hyperlink from '../Hyperlink/Hyperlink';

const Image = (props) => {

    const getHoverState = (link) => {
        if (link.text) {
            return (<HoverCta icon='text' text={link.text}>{asset}</HoverCta>);
        } else {
            return (<HoverCta icon='gallery'>{asset}</HoverCta>);
        }
    }


    const classes = props.data.adjacent_text_location ? `Image adjacent-position-${props.data.adjacent_text_location}` : `Image`;
    const link = props.data.link && props.data.link.length ? props.data.link[0] : null; 
    const size = props.data.size ? `asset-${props.data.size}` : null;
    const image = ( <img className={['asset', size].join(' ')} src={props.data.image.filename} title={props.data.image.title} alt={props.data.image.alt} />  );
    
    const asset = link ? (<Hyperlink data={link}>{image}</Hyperlink>) : image;
    const wrapped = link ? getHoverState(link) : asset;

    // Caption
    const caption = props.data.adjacent_text.length && props.data.adjacent_text[0].copy.markup ? (
        <figcaption className='adjacent'>
            <Text data={props.data.adjacent_text[0]}></Text>
        </figcaption>
    ) : null;

    return (
        <AnimateElementIn>
            <figure className={classes}>
                {wrapped}
                {caption}
            </figure>
        </AnimateElementIn>
    )
}

export default Image;