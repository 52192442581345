// Libraries
import React from 'react';

// Styling
import './AddressList.scss';

const AddressList = (props) => {

    const list = props.list.map( (group) => {

        return [
            (<div className="group" key={group.name}>
                <h1 className={ props.tutors === true ? "letter red tutors" : "letter red bold"}>{ group.name.split(',')[0]}</h1>
                <span id={group.name}></span>
              </div>),
            [...group.items.map( (item, index) => {
                return ( 
                    <p className="text" key={`${item[0]}_${index}`}>{item[0]}</p>
                )
            })]
        ];
    });

    return (
        <div className="AddressList">
            { list }
        </div>
    );

}

export default AddressList;