import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './MasterclassList.scss';

class MasterclassList extends Component {

    state = {
        animateInState: true,
        masterclasses: []
    }

    getData() {
        Storyblok.fetchShowMasterclasses().then( (masterclasses) => {
            this.setState({masterclasses, animateInState: false});
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        
        const classes = this.state.animateInState ? 'MasterclassList animate-component-in-state' : 'MasterclassList';
        const showName = Storyblok.site.active_show.name;

        const list = this.state.masterclasses ? this.state.masterclasses.map( (masterclass, index) => (
            <Link to={`/${showName}/masterclass/` + masterclass.slug}  key={masterclass.uuid} className="link">
                <span className="number semi-bold black">{ ('0' + (index + 1)).substr(-2) }</span>
                <span className="name bold">{ masterclass.display_name }</span>
            </Link>
        )) : null;
        
        return (
            <div className={classes}>
                {list}
            </div>
        )
    }
}

export default MasterclassList;