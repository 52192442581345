import React from 'react';
import './Title.scss';

const Title = (props) => {
    const timePerLetter = 1;
    const time = `${props.data.text.length * timePerLetter}s`;
    const style = {animationDuration: time};
    let baseClass = props.data.theme === 'red' ? 'Title theme-red' : 'Title theme-black';
    baseClass = props.data.text.length > 8 ? `${baseClass} long-name` : baseClass;

    return (
        <div className={baseClass}>
            <h1 className="block">
                <span className="block-item bold" style={style}>
                    {props.data.text}
                    <div className="block-dash"></div>
                    {props.data.text}
                    <div className="block-dash"></div>
                </span><span className="block-item bold" style={style}>
                    {props.data.text}
                    <div className="block-dash"></div>
                    {props.data.text}
                    <div className="block-dash"></div>
                </span>
            </h1>
        </div>
    )
}

export default Title;