// Libraries
import { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

class BaseComponent extends Component {
    constructor(props) {
        super( props );

        const showName = props.match.params.year;
        
        if (showName) {
            Storyblok.showName = showName;
        }
    }

    get activeShowName() {
        return Storyblok.site.active_show ? Storyblok.site.active_show.name : '';
    }

}

export default BaseComponent;