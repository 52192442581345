// Libraries
import React, { Component } from 'react';

// Styling
import './ImageCrossfade.scss';

class ImageCrossfade extends Component {

    state = {
        src: null,
        fadeout: false
    }

    componentDidMount() {
        this.changeImage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this.changeImage();
        }
    }

    changeImage() {
        const setSrc = () => this.setState( { 
            src: this.props.src
        } );

        if (!this.state.src) {
            setSrc();
        } else {
            this.setState( { fadeout: true });
            setTimeout( setSrc , (this.props.crossfadeDuration || 2) * 1000 / 2);
        }
    }

    render() {

        const style = {
            transitionDuration: `${(this.props.crossfadeDuration || 2)/2}s`
        }

        const className = this.state.fadeout ? 'fadeout' : '';

        const onImageLoad = () => {
            this.setState( {fadeout: false} );
        }

        return (
            <div className="ImageCrossfade">
                <img alt="slideshow" style={style} className={className} src={this.state.src} onLoad={onImageLoad}/>
            </div>
        )

    }
}

export default ImageCrossfade;