// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Masterclasses.scss';

import Cursor from '../../components/Cursor/Cursor';
import ModuleBlock from '../../components/Modules/ModuleBlock/ModuleBlock';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class Masterclasses extends BaseComponent {

    state = {
        structure: null
    }

    getData() {
        Storyblok.fetchShowPage('masterclasses').then( (masterclasses) => {
            this.setState({structure: masterclasses.structure});
        });
    }

    componentDidMount() {
        Track.page( 'Masterclasses', '/masterclasses' );
        this.getData();
    }

    render() {
        return (
            <Cursor>
                <ScrollDirection>
                    <div className="Masterclasses">
                        { this.state.structure ? this.state.structure.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : <Loader theme="white" /> }
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default Masterclasses;