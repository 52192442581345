// Libraries
import React, { Component } from 'react';

// Styling
import './Loader.scss';

class Loader extends Component {
    
    state = {
        active: this.props.startActive ? true : false
    }

    componentDidMount() {
        this.timer = setTimeout( () => this.setState({ active: true }), 250 );
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const classes = this.state.active ? `Loader active` : `Loader`;
        const theme = this.props.theme === 'white' ? 'spinner spinner-white' : 'spinner';
        
        return (
            <div className={classes}>
                <svg className={theme} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
        );
    }
}

export default Loader;