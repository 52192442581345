// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track'

// Styling
import './HomePortraits.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import ColourCrossfade from '../../components/ColourCrossfade/ColourCrossfade';
import ImageCrossfade from '../../components/ImageCrossfade/ImageCrossfade';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

// Assets
import Session from '../../classes/session';
import Loader from '../../components/Loader/Loader';

class HomePortraits extends BaseComponent {

    constructor(props) {
        super(props);
        this.clubSelectRef = React.createRef();
        this.slideshowTimer = null;
        this.onSlideshowTimer = this.onSlideshowTimer.bind(this);
    }

    state = {
        animateInState: true,
        content: {},
        clubs: [],
        gallery: [],
        loading: true,
        step:'home',
        launchVideoActive: false,
        slideshowIndex: -1,
        crossfadeDuration: 2
    }

    updateClub = async () => {
        const club = this.clubSelectRef.current.value;
        const slug = await Storyblok.clubSlugFromId( club )
        this.props.history.push(`/club/${slug}`);
    }

    onSlideshowTimer() {
        let newIndex = this.state.slideshowIndex+1;
        newIndex = (newIndex >= this.state.content.slideshow.length) ? 0 : newIndex;
        this.setState({ slideshowIndex: newIndex } )
    }
    
    viewPage(type, id) {
        switch (type) {
            case 'club':
                Storyblok.clubSlugFromId( id )
                    .then( slug => this.props.history.push(`/${this.activeShowName}/club/${slug}`) );
                break;
            case 'masterclass':
                Storyblok.masterclassSlugFromId( id )
                    .then( slug => this.props.history.push(`/${this.activeShowName}/masterclass/${slug}`) );
                break;
            case 'showcase':
                const event = new CustomEvent('showcase', { detail: id });
                window.dispatchEvent(event);
                break;
            default: return;
        }
    }

    applyAnimation() {
        if (this.state.step === 'home') {
            this.timer = setTimeout( () => this.setState({animateInState: false}))
        }
    }

    getData() {
        return Storyblok.fetchShowPage('home').then( (home) => {
            this.setState({content: home});
            Storyblok.fetchHomePageImages().then( (homepageContent) => {
                Storyblok.fetchShowMasterclasses().then( (masterclasses) => {
                    Storyblok.fetchShowClubs().then( (clubs) => {
                        const gallery = homepageContent.map( (item, index) => {
                            const contentMatch = item.component === 'Club Page' ? 
                                clubs.find( (club) => item.uuid === club.uuid ) :
                                masterclasses.find( (masterclass) => item.uuid === masterclass.uuid);

                            if (contentMatch) {
                                const cta = item.component === 'Club Page' ? 
                                    (<p className="white small bold">{this.state.content.copy_view_club_page}</p>) :
                                    (<p className="white small bold">{this.state.content.copy_view_masterclass}</p>);

                                const image = Object.assign({}, item);
                                delete image.uuid;
                                delete image.component;

                                return {
                                    image,
                                    card: { 
                                        name: contentMatch.shortName ? contentMatch.shortName : contentMatch.name,
                                        subname: contentMatch.category && contentMatch.category.display_name ? contentMatch.category.display_name : null,
                                        cta
                                    },
                                    callback: item.component === 'Club Page' ? () => this.viewPage('club', item.uuid) : () => this.viewPage('masterclass', item.uuid)
                                }
                            } else {
                                return null;
                            }
                        }).filter( (item) => item );

                        this.setState({loading: false, clubs, gallery}, this.applyAnimation);
                    });
                });
            });
        })
    }

    componentDidMount() {
        if (Storyblok.showLaunchVideo && !Session.launchVideoViewed) {
            this.props.history.push('/launch' );
        } else {
            Track.page( 'Home', '/home' );
            this.getData()
                .then(()=> {
                    this.onSlideshowTimer(); // do first slide
                    const cf = parseInt( this.state.content.slideshow_crossfade_duration ) || 2;
                    const duration = (parseInt( this.state.content.slideshow_duration ) || 5) * 1000;
                    const crossfade = cf * 1000;
                    this.slideshowTimer = setInterval( this.onSlideshowTimer, duration + crossfade );
                    this.setState( {crossfadeDuration: cf})
                })
        }
    }

    componentWillUnmount() {
        clearInterval(this.slideshowTimer);
        clearTimeout(this.timer);
    }

    render() {
        const homeClasses = this.state.animateInState ? 'Home animate-page-in-state' : 'Home';
        const showSelfPortraits = () => {
            this.props.history.push(`/${this.activeShowName}/self-portraits` );
        }
        const colourCrossfadecolor = this.state.slideshowIndex >= 0 ?
            this.state.content.slideshow[this.state.slideshowIndex].colour : '#ffffff';

        const crossfadeDuration = `${this.state.crossfadeDuration || 2}s`;

        const slideSrc = this.state.slideshowIndex >= 0 ?
            this.state.content.slideshow[this.state.slideshowIndex].image.filename : null;

        const template = this.state.loading ? ( <div className="loading"><Loader /></div> ) : (
            <Cursor>

                <div className="home-container">
                    <ColourCrossfade text={this.state.content.copy_title} colour={colourCrossfadecolor} crossfadeDuration={crossfadeDuration}></ColourCrossfade>
                    <div className={homeClasses}>
                        <div className="mainLayout">
                            <ImageCrossfade src={slideSrc}></ImageCrossfade>
                            <div className="Text">
                                <div className="text-content">
                                    <h1 className="xxl bold red pad-bottom">{this.state.content.copy_title}</h1>
                                    <p className="pad-bottom">
                                        <span className="regular bold">{this.state.content.copy_message}</span>
                                    </p>
                                    <button type="button" className="button" onClick={()=>showSelfPortraits()}>{this.state.content.copy_explore_the_self_portraits}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Cursor>
        );

        return template;
    }
}

export default HomePortraits;