// Libraries
import React, { Component } from 'react';
import Player from '@vimeo/player';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './LandingAnimation.scss';
import Loader from '../Loader/Loader';

class LandingAnimation extends Component {

    state = {
        loading: true,
        videoId: window.innerWidth > window.innerHeight ? Storyblok.site.active_show.preloader_vimeo_id_landscape : Storyblok.site.active_show.preloader_vimeo_id_portrait
    }

    player = null;

    onVideoPlay = () => {
        this.setState({loading: false});
    }

    onVideoEnd = () => {
        if (this.props.completed) {
            this.props.completed();
        }
    }

    setupVideo() {
        this.player = new Player(`video_${this.state.videoId}`, {
            id: this.state.videoId,
            controls: false,
            playsinline: true,
            muted: true,
            autoplay: true,
            width: 1920
        });

        // Check video Id is valid
        this.player.getVideoId()
          .catch(this.onVideoEnd);

        this.player.on('play', this.onVideoPlay);
        this.player.on('ended', this.onVideoEnd);
        this.player.on('error', this.onVideoEnd);
    }

    componentDidMount() {
        this.setupVideo();
    }

    componentWillUnmount() {
        this.player.off('play', this.onVideoplay);
        this.player.off('ended', this.onVideoEnd);
        this.player.off('error', this.onVideoEnd);
        this.player.destroy();
    }

    render() {
        return (
            <div className='LandingAnimaton'>
                { this.state.loading ? <Loader startActive={true} /> : null }
                <div className='content' id={`video_${this.state.videoId}`}></div>
            </div>
        );
    }
}

export default LandingAnimation;