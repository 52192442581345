// Libraries
import React, { Component } from 'react';

// Styling
import './ColourCrossfade.scss';

class ColourCrossfade extends Component {
    render() {

        const content = '12345678901234567890'.split('').map( words => (this.props.text || 'Hello' ) ).join( ' ' );

        const style = {
            backgroundColor: this.props.colour || '#ffffff',
            transitionDuration: `${this.props.crossfadeDuration || 2}s`
        }

        return (
            <div style={style} className="ColourCrossfade">{ content }</div>
        )
    }
}

export default ColourCrossfade;