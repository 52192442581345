import React, { Component } from 'react';

import './AnimateElementIn.scss';

class AnimateElementIn extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    state = {
        animateInState: true
    }

    observer = null;

    handleObserver = (entries, observer) => {
        entries.forEach( (entry) => {
            if (entry.isIntersecting) {
                this.setState({animateInState: false}, this.observer.disconnect());
            }
        });
    }

    setupObserver() {
        const options = {
            root: null,
            threshold: [0.2]
        };

        // Checks if the anchor is in view
        this.observer = new IntersectionObserver( this.handleObserver, options);
        this.observer.observe(this.containerRef.current);
    }

    componentDidMount() {
        this.timer = setTimeout( () => this.setupObserver(), 250);
    }

    componentWillUnmount() {
        this.observer.disconnect();
        clearTimeout(this.timer);
    }

    render() {
        const classes = this.state.animateInState ? `AnimateElementIn animate-in-state` : 'AnimateElementIn';

        return (
            <div className={classes} ref={this.containerRef}>{this.props.children}</div>
        );
    }
}

export default AnimateElementIn;