// Libraries
import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Partners.scss';

import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import ModuleBlock from '../../components/Modules/ModuleBlock/ModuleBlock';
import Loader from '../../components/Loader/Loader';

class Partners extends Component {

    state = {
        structure: null
    }

    getData() {
        Storyblok.fetchShowPage('partners').then( (partners) => {
            this.setState({structure: partners.structure});
        });
    }

    componentDidMount() {
        this.getData();
        Track.page( 'Partners', '/partners' );
    }

    render() {
        return (
            <Cursor>
                <ScrollDirection>
                    <div className="Partners">
                        { this.state.structure ? this.state.structure.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : <Loader /> }
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default Partners;