// Libraries
import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './CookieBar.scss';

class CookieBar extends Component {

    state = {
    }

    agree() {
        window.localStorage.setItem( 'cookie-accepted', 'yes' );
        this.forceUpdate();
    }

    render() {

        if (window.localStorage.getItem( 'cookie-accepted' ) === 'yes') {
            return null;
        }

        return (
            <div className="CookieBar">
                <div className="message">{ Storyblok.site.copy_cookie_bar_message }</div>
                <div className="buttons">
                    <div className="square-button agree" onClick={() => this.agree()}>{ Storyblok.site.copy_cookie_bar_agree }</div>
                    <a className="square-button policy" href={Storyblok.site.cookie_bar_policy_url } rel="noopener noreferrer" target="_blank">{ Storyblok.site.copy_cookie_bar_policy }</a>
                </div>
            </div>
        )
    }
}

export default CookieBar;