// Libraries
import React, { Component } from 'react';

// Styling
import './MasonryGallery.scss';

class MasonryGallery extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    state = {
        activeGroup: null,
        activeCell: null,
        structure: [],
        timer: null,
        touchDevice: false
    }

    setTouchDevice() {
        this.setState({touchDevice: true})
    }

    activateCell(group, cell, callback) {
        if (this.state.touchDevice === true) {
            // hover unavailable
            if (this.state.activeGroup === group && this.state.activeCell === cell) {
                callback();
            } else {
                this.setState({activeGroup: group, activeCell: cell});
            }
        } else {
            callback();
        }
        
    }

    loadImage(item) {
        return new Promise( (resolve, reject) => {
            const image = new Image();

            image.onload = () => {
                item.image.size = {
                    w: image.width,
                    h: image.height
                }
                resolve(item);
            };

            image.onerror = () => {
                item.image.size = {
                    w: 0,
                    h: 0
                }
                resolve(item);
            }

            image.src = item.image.filename;
        });
    }

    populateImages(sizing) {
        return new Promise( (resolve, reject) => {
            const loaders = this.props.items.map( (item) => {
                if (item.image.size) {
                    return Promise.resolve(item);
                } else {
                    return this.loadImage(item);
                }
            });

            Promise.all(loaders).then( (res) => {
                let groups = new Array(sizing.groups).fill(0);
                groups = groups.map( () => Object.assign({}, {
                    distance: 0,
                    items: []
                }) );

                const data = res.reduce( (acc, item) => {
                    const itemIndex = acc.reduce( (curr, next, index) => {
                        return (curr.index === null || next.distance < curr.distance) ? 
                            ({ index: index, distance: next.distance }) :
                            curr;
                    }, {index: null, distance: -1} );

                    if (sizing.constraint === 'width') {
                        const height = (sizing.groupSize / item.image.size.w) * item.image.size.h;
                        const width = sizing.groupSize;
                        item.image.size = {h: height, w: width};
                        acc[itemIndex.index].distance += height;
                        acc[itemIndex.index].items.push(item);
                    } else {
                        const width = (sizing.groupSize / item.image.size.h) * item.image.size.w;
                        const height = sizing.groupSize;
                        item.image.size = {h: height, w: width};
                        acc[itemIndex.index].distance += width;
                        acc[itemIndex.index].items.push(item);
                    }

                    return acc;
                }, groups);

                resolve(data);
            });
        })
    }

    defineSizeByHeight() {
        const bounding = this.containerRef.current.getBoundingClientRect();
        const height = bounding.height;
        const maxColumnItemHeight = this.props.large ? 300 : 250;
        const groups = Math.ceil(height / maxColumnItemHeight)
        return {
            groups,
            groupSize: (height / groups) - 3,
            constraint: 'height'
        }
    }

    defineSizeByWidth() {
        const bounding = this.containerRef.current.getBoundingClientRect();
        const width = bounding.width;
        const maxColumnItemWidth = this.props.large ? 375 : 250;
        const groups = Math.ceil(width / maxColumnItemWidth);
        return {
            groups,
            groupSize: (width / groups) - 3,
            constraint: 'width'
        }
    }

    // Resize iframe to be the correct size
    getSizes() {
        if (this.props.preventHorizontal || window.innerWidth <= window.innerHeight) {
            const sizing = this.defineSizeByWidth();
            this.populateImages(sizing).then( (structure) => {
                this.setState({structure} )
            });
        } else {
            const sizing = this.defineSizeByHeight();
            this.populateImages(sizing).then( (structure) => {
                this.setState({structure} )
            });
        }
    }

    screenResized = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout( () => this.getSizes(), 250 );
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items) {
            this.getSizes();
        }
    }

    componentDidMount() {
        this.getSizes();
        window.addEventListener('resize', this.screenResized);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        window.removeEventListener('resize', this.screenResized);
    }
    
    render() {
        const classes = this.props.preventHorizontal ? `MasonryGallery` : 'MasonryGallery horizontal';

        const groups = this.state.structure.length ? this.state.structure.map( (group, groupIndex) => (
            <div className="gallery-group" key={`group_${groupIndex}`}>
                {group.items.map( (item, cellIndex) => (
                    <div
                        className={this.state.activeGroup === groupIndex && this.state.activeCell === cellIndex ? 'gallery-cell active' : 'gallery-cell'}
                        onTouchStart={this.setTouchDevice.bind(this)}
                        onClick={this.activateCell.bind(this, groupIndex, cellIndex, item.callback)}
                        style={{
                            width: `${item.image.size.w}px`,
                            height: `${item.image.size.h}px`
                        }}
                        key={`cell_${cellIndex}`}>
                        <img className={this.props.showExpandCursor ? "gallery-image cursor-expand" : "gallery-image"} src={item.image.filename} alt={item.image.alt} loading="lazy" />
                        <div className={this.props.showExpandCursor ? "gallery-card cursor-expand" : "gallery-card"}>
                            <div className="gallery-card-content">
                                <h3 className="white small bold gallery-card-subname">{item.card.subname}</h3>
                                <h1 className="white medium bold gallery-card-name">{item.card.name}</h1>
                                { item.card.cta ? (
                                    <div className="white small bold gallery-card-links">{item.card.cta}</div>
                                ) : null }
                            </div>
                        </div>
                    </div>
                ) )}
            </div>
        )) : null;

        return (
            <div className={classes} ref={this.containerRef}>
                <div className="gallery-root">
                    { groups }
                </div>
            </div>
        );
    }
}

export default MasonryGallery;