// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Search.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class Search extends BaseComponent {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        animateInState: true,
        categories: [],
        clubs: null
    }
    allClubs = [];
    touchDevice = false;

    touchHandler(name) {
        this.touchDevice = true;
        this.applyFilter(name);
    }

    mouseHandler(name) {
        if (!this.touchDevice) {
            this.applyFilter(name);
        } else {
            this.touchDevice = false;
        }
    }

    applyFilter(name) {
        const categories = [...this.state.categories].map( (category) => {
            category.applied = !category.applied && category.name === name ? true : false
            return category;
        });

        this.setState({categories}, this.applySearch);
    }

    get hasFilter() {
        return this.state.categories.find( item => item.applied );
    }

    applySearch = () => {
        let clubs = this.allClubs;
        if (this.inputRef.current.value) {
            const value = this.inputRef.current.value.toLowerCase();
            clubs = clubs.filter( (club) => {
                const name = club.displayName.toLowerCase();
                return name.includes(value);
            });
        }

        const filters = this.state.categories
            .filter( (category) => category.applied ).map( (category) => category.name );
        if (filters.length) {
            clubs = clubs.filter( (club) => filters.includes(club.category.name) );
        }

        this.setState({clubs});
    }

    getData() {
        Storyblok.fetchShowClubs().then( (res) => {
            this.allClubs = res.map( (club) => {
                club.displayName = club.alternate_name ? club.alternate_name : club.name;
                return club;
            }).sort( (a, b) => a.displayName.localeCompare(  b.displayName ) );

            const categories = Object.values(Storyblok.clubCategories).map( (category) => {
                const clubsNumber = this.allClubs.reduce( (total, club) => {
                    total = club.category.uuid === category.uuid ? total + 1 : total
                    return total;
                }, 0)
                category.clubsNumber = clubsNumber;
                category.applied = false;
                return category;
            }).sort( (a, b) => a.display_name.localeCompare(b.display_name) );

            const clubs = [...this.allClubs];

            this.setState({categories, clubs}, this.applyAnimation);
        });
    }

    applyAnimation() {
        this.timer = setTimeout( () => this.setState({animateInState: false}));
    }

    componentDidMount() {
        this.getData();
        Track.page( 'Search', '/search' );
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const categories = (
            <ul className="tags">
                { this.state.categories.map( (category, index) => {
                    return (
                        <li className="tag" key={category.name} style={{transitionDelay: (0.1 * index + 0.5) + 's'}}>
                            <button
                                type="button"
                                className={category.applied ? 'button active' : 'button'}
                                onClick={() => this.mouseHandler(category.name)}
                                onTouchStart={() => this.touchHandler(category.name)} >{ category.display_name} ({category.clubsNumber})</button>
                        </li>
                    )
                }) }
            </ul>
        );

        const clubs = (
            <div className="clubs">
                { this.state.clubs ? (
                    this.state.clubs.length ? this.state.clubs.map ( (club, index) => {
                        return (
                            <Link key={club.uuid} className="club" to={`/${this.activeShowName}/club/${club.slug}`} style={{'transitionDelay': (index * 0.05 + 0.5) + 's'}}>
                                <p className="club-name regular bold">{ club.shortName }</p>
                                { !this.hasFilter && 
                                    <p className="club-category small">{ club.category.name }</p>
                                }                 
                            </Link>
                        )
                    }) : (<p className="regular bold no-clubs">There were no clubs found</p>) 
                ) : null }
            </div>
        );

        const classes = this.state.animateInState ? 'Search animate-page-in-state' : 'Search';

        const template = (
            <Cursor>
                <div className={classes}>
                    <input className="input" ref={this.inputRef} type="text" placeholder="Search here" onChange={this.applySearch} />
                    {categories}
                    <ScrollDirection>{clubs}</ScrollDirection>
                </div>
            </Cursor>
        )

        return template;
    }
}

export default Search;