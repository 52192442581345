import React, { Component } from 'react';

// Styling
import './Cursor.scss';
import Session from '../../classes/session';

class Cursor extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    state = {
        color: 'red',
        cursor: {x: null, y: null},
        style: null
    }

    container = null;
    touchDevice = false;

    getContainerSize = () => {
        const boundingRect = this.containerRef.current.getBoundingClientRect();
        this.container = {
            left: boundingRect.left,
            top: boundingRect.top,
            width: boundingRect.width,
            height: boundingRect.height
        }
    }

    // Finds the current percentage position of the mouse within the element
    findCurrentPosition(event) {
        if (this.container) {
            const distanceX = event.clientX - this.container.left;
            const distanceY = event.clientY - this.container.top;
            return {
                x: distanceX,
                y: distanceY,
                width: this.container.width,
                height: this.container.height
            }
        }
    }

    findIfSpecialElement = (targetElement) => {
        let isInContent = false;
        // walk up the tree checking for the given class
        while (targetElement && !isInContent) {
            isInContent = ['BUTTON', 'A'].includes(targetElement.tagName) ? targetElement.tagName : false;
            if (!isInContent) {
                isInContent = targetElement.classList.contains('cursor-expand') ? 'cursor-expand' : false;
            }
            if (!isInContent) {
                isInContent = targetElement.classList.contains('cursor-hide') ? 'cursor-hide' : false;
            }
            targetElement = targetElement.parentElement;
        }
        return isInContent;
    }

    getStyle(elementType) {
        switch (elementType) {
            case 'button': return 'hide';
            case 'a': return 'enlarge';
            case 'cursor-expand': return 'expanded';
            case 'cursor-hide': return 'hide';
            default: return null;
        }
    }

    overRedTheme(el) {
        let isInContent = false;
        // walk up the tree checking for the given class
        while (el && !isInContent) {
            isInContent = el && typeof el.className === 'string' && el.className.includes('theme-red') ? true : false;
            el = el.parentElement;
        }
        return isInContent;
    }

    touchStartHandler = (event) => {
        this.touchDevice = true;
    }

    // Handles when the mouse is moved within the element
    mouseMoveHandler = (event) => {
        if (!this.touchDevice) {
            const currentTarget = this.findIfSpecialElement(event.target);
            const style = this.getStyle(`${currentTarget}`.toLowerCase());
            const color = this.overRedTheme(event.target) ? 'white' : 'red';
            const pos = this.findCurrentPosition(event);
            this.setState({ cursor: {x: pos.x, y: pos.y}, style, color });
        } else {
            this.touchDevice = false;
        }
    }

    // Handles when mouse leaves the element
    mouseLeaveHandler = (event) => {
        this.setState({ cursor: {x: null, y: null} });
    }

    componentDidMount() {
        this.getContainerSize();
        window.addEventListener('resize', this.getContainerSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getContainerSize);
    }

    render() {
        const cursorPosition = {
            transform: `translate( calc(-50% + ${this.state.cursor.x}px), calc(-50% + ${this.state.cursor.y}px))`
        }

        let cursorClasses = this.state.style ? `cursor-icon ${this.state.style}` : 'cursor-icon';
        cursorClasses = this.state.color === 'white' ? `${cursorClasses} cursor-white` : cursorClasses;
        const cursor = this.state.cursor.x ? <div className={cursorClasses} style={cursorPosition}></div> : null;

        if (Session.isIE) {
            return(
                <div className="Cursor" ref={this.containerRef}>
                    {this.props.children}
                </div>
            )
        } else {
            return(
                <div
                    ref={this.containerRef}
                    className="Cursor"
                    onTouchStart={this.touchStartHandler}
                    onMouseMove={this.mouseMoveHandler}
                    onMouseLeave={this.mouseLeaveHandler}>
                    {this.props.children}
                    {cursor}
                </div>
            )
        }
    }
}

export default Cursor;