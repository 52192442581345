// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Masterclass.scss';

import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import ModuleBlock from '../../components/Modules/ModuleBlock/ModuleBlock';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

class Club extends BaseComponent {

    state = {
        structure: null,
        id: null,
        prev_id: null
    }

    constructor( props ) {
        super( props );
        this.scrollerRef = React.createRef();
    }

    getData() {
        Storyblok.fetchShowMasterClass( this.props.match.params.id ).then( (masterclass) => {
            this.setState({structure: masterclass.structure});
        });
    }

    componentDidMount() {
        this.setState( { id: this.props.match.params.id } );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.prev_id !== this.state.id) {
            Storyblok.fetchShowMasterClass( this.state.id, true ).then( (masterclass) => {
                this.setState({structure: masterclass.structure, prev_id: this.state.id });
                this.scrollerRef.current.reset();
                Track.page( 'Masterclass', '/masterclass', {
                    custom_map: {
                        dimension2: 'masterclass_name'
                    },
                    masterclass_name: masterclass.display_name
                });
            }).catch( err => this.props.history.push( `/${Storyblok.site.active_show.name}` ) );
        }
    }
    
    static getDerivedStateFromProps(props, state) {
        const newId = props.match.params.id;
        return (newId && newId !== state.id) ? { id: newId } : null;
    }

    render() {
        return (
            <Cursor>
                <ScrollDirection ref={this.scrollerRef}>
                    <div className="Masterclass">
                        { this.state.structure ? this.state.structure.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : <Loader /> }
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default Club;