const privates = {
    launchVideoViewed: false,
    launchAnimationPlayed: false
}

class Session {
    static set launchVideoViewed( flag ) {
        privates.launchVideoViewed = flag;
    }

    static get launchVideoViewed() {
        return privates.launchVideoViewed;
    }

    static set launchAnimationPlayed( flag ) {
        privates.launchAnimationPlayed = flag;
    }

    static get launchAnimationPlayed() {
        return privates.launchAnimationPlayed;
    }

    static get isIE() {
        return typeof window.msCrypto !== 'undefined';
    }
}

export default Session;