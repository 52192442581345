// Libraries
import React from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Members.scss';

// Components
import AddressList from '../../components/AddressList/AddressList';
import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';
import BaseComponent from '../../components/BaseComponent/BaseComponent';

// Design
import AddressListSelector from '../../components/AddressList/AddressListSelector/AddressListSelector';

class Members extends BaseComponent {

    state = {
        animateInState: true,
        categories: [],
        content: null,
        list: null,
        tutors: false,
        memberslist: null,
        title: '',
        message: ''
    }

    constructor( props ) {
      super(props);
      this.scroller = React.createRef();
    }
    // Gets the list to display in the template
    getDisplayList() {
        const category = this.state.categories.find( category => category.active);
        this.setState({
          list: category.data,
          tutors: category.tutors === true,
          title: this.state.content[ category.title ],
          message: this.state.content[ category.message ]
        });
    }

    // Applies a filter to the list - find the list again
    applyFilter(id) {
        [...this.state.categories].forEach( category => category.active = category.id === id );
        this.getDisplayList();

        try {
            this.scroller.current.reset();
        } catch (e) {}
      }

    // Gets the category list that the list can be filtered by
    getCategories(groups, lists) {
        return groups.map( (group, index) => {
            return {
                id: group.name,
                active: group.active === true,
                copy: `copy_filter_${group.name}`,
                title: `copy_${group.name}_title`,
                message: `copy_${group.name}_message`,
                data: lists[ index ],
                tutors: group.tutors === true
            }
        });
    }

    jumpToSection(letter) {
        var element = document.getElementById(letter);
        element.scrollIntoView({behavior: "smooth"})
    }

    // Gets the required data to display the page
    getData() {
        
        const groups = [
          {
            name: 'club_members',
            active: true,
            group: {
              column: 0,
              first_letter: true
            }
          }, 
          {
            name: 'tutors',
            group: {
              column: 1,
              first_letter: false
            },
            tutors: true
          },
          {
            name: 'assistants',
            group: {
              column: 1,
              first_letter: false
            },
            tutors: true
          }
        ];

        const loaders = groups.map( (group) => Storyblok.fetchList(group) )

        Promise.all(loaders).then( (lists) => {
            Storyblok.fetchPage('members').then( (content) => {
                const memberslist = lists[0];
                const categories = this.getCategories(groups, lists );
                this.setState({categories, content, memberslist}, this.applyAnimation);
                this.getDisplayList();
            });
        });
    }

    applyAnimation() {
        this.timer = setTimeout( () => this.setState({animateInState: false}));
    }

    componentDidMount() {
        this.getData();
        Track.page( 'Members', '/members' );
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const categories = this.state.content ? (
            <ul className="tags">
                { this.state.categories.map( (category) => {
                    return (
                        <li className="tag" key={category.id}>
                            <button
                                type="button"
                                className={category.active ? 'button active' : 'button'}
                                onClick={() => this.applyFilter(category.id)}
                                >{this.state.content[category.copy]}</button>
                        </li>
                    )
                }) }
            </ul>
        ) : null;

        const pageContent = this.state.content ? (
            <div className="page">
                <h1 className="xl bold red">{this.state.title}</h1>
                <p className="regular bold message">{this.state.message}</p>
            </div>
        ) : null;

        const classes = this.state.animateInState ? 'Members animate-page-in-state' : 'Members';

        const template = (
            <Cursor>
                <div className={classes}>
                    <div className="options">
                        { categories }
                        { this.state.list ? (
                            <div className="nav">
                                <AddressListSelector tutors={this.state.tutors} list={this.state.memberslist} horizontal="true"></AddressListSelector>
                            </div>
                        ) : null }
                    </div>
                    <ScrollDirection ref={this.scroller}>
                        <div className="content" >
                            { pageContent }
                            <div className="list">
                                { this.state.list ? <AddressList list={this.state.list} tutors={this.state.tutors}></AddressList> : null }
                                <div className="nav">
                                    { this.state.list ? <AddressListSelector tutors={this.state.tutors} list={this.state.memberslist}></AddressListSelector> : null }
                                </div>
                            </div>
                        </div>
                    </ScrollDirection>
                </div>
            </Cursor>
        )

        return template;
    }
}

export default Members;