// Libraries
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import React, { Component } from 'react';
import Player from '@vimeo/player';
import Text from '../../components/Modules/Text/Text';
import Session from '../../classes/session';
import Track from '../../classes/track';

// Styling
import './Launch.scss';

class Launch extends Component {

    state = {
        page: null,
        ready: false,
        player: null,
        showOverlay: true,
        init_overlay: false
    }

    async componentDidMount() {

        Track.page( 'Launch', '/launch' );

        // This probably should be set after the video has finished??!?!
        Session.launchVideoViewed = true;
        Session.launchAnimationPlayed = false;

        const self = this;

        Storyblok.fetchShowPage( 'launch' ).then( page => {

            const player = new Player( `launch_video`, {
                id: page.vimeo_video_id,
                byline: false,
                title: false,
                playsinline: false,
                portrait: false,
                responsive: true
            });

            player.on( 'ended', () => {
                self.props.history.push('/')
            });

            this.setState( { ready: true, page, player } );
            setTimeout( () => {
              this.setState( {init_overlay: true } );
            }, 500 ) 

        });
    }

    componentWillUnmount() {
    }

    playVideoHandler = () => {
        this.setState( { showOverlay: false }, () => {
            this.state.player.play() 
        });
    }

    render() {

        const overlayStyle = this.state.page ? {
            backgroundImage: `url(${this.state.page.video_overlay_image.filename})`
        } : {}

        const overlay = this.state.page ? (
            <div className={'overlay ' + (this.state.showOverlay ? '' : 'hidden') } style={overlayStyle}>
                { this.state.init_overlay && (
                  <button className="start-button" onClick={this.playVideoHandler}>{ this.state.page.video_play_copy }</button>
                )}
            </div>
        ) : null;

        const logo = this.state.page && this.state.page.logo && this.state.page.logo.filename ? (
            <img className="logo" src={this.state.page.logo.filename} alt={this.state.page.logo.alt} />
        ) : null;

        const video = (
            <div className="video-container" id="launch_video">
                { overlay }
            </div>
        )

        const copy = this.state.page && this.state.page.copy && this.state.page.copy.length ? (
            <div><Text data={this.state.page.copy[0]}></Text></div>
        ) : null;

        return (
            <div className="Launch">
                { logo }
                { video }
                { copy }
            </div>
        );

    }
}

export default Launch;