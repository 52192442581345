import React, { Component } from 'react';

// Styling
import './ScrollDirection.scss';

class ScrollDirection extends Component {

    // How wide the screen needs to be before changing to horizontal;
    // If changed, change $width-horizontal-scroll in index.scss to same value
    horizontalScrollStart = 500;

    previousScreenSize = window.innerWidth;
    previousScreenOr = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    reset() {
        this.containerRef.current.scrollTop = 0;
        this.containerRef.current.scrollLeft = 0;
    }

    checkScrollPosition = (event) => {
        if (
            ( ((window.innerWidth > window.innerHeight) && this.previousScreenOr === 'portrait') || 
            ( (window.innerWidth <= window.innerHeight) && this.previousScreenOr === 'landscape') )
        ) {
            this.containerRef.current.scrollLeft = 0;
            this.containerRef.current.scrollTop = 0;
        }
    }

    onWheelHandler = (event) => {
        // Firefox Does scrolling by lines not pixels. Rough calculation is 1 line is 16px
        if (event.deltaMode === 1) {
            event.deltaX = event.deltaX * 16;
            event.deltaY = event.deltaY * 16;
        }
        if (window.innerWidth > window.innerHeight) {
            if ( Math.abs(event.deltaY) > Math.abs(event.deltaX) ) {
                this.containerRef.current.scrollLeft += (event.deltaY * 1);
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkScrollPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkScrollPosition);
    }

    render() {
        return (
            <div ref={this.containerRef} className="HorizontalScroll" onWheel={this.onWheelHandler}>
                {this.props.children}
            </div>
        )
    }
}

export default ScrollDirection;