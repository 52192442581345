import React, { Component } from 'react';
import './InfiniteScroll.scss';

class InfiniteScroll extends Component {

    constructor(props) {
        super(props);
        this.anchorRef = React.createRef();
        this.containerRef = React.createRef();
        this.observer = null;
        this.timer = null;
    }

    handleObserver = (entries, observer) => {
        entries.forEach( (entry) => {
            if (entry.isIntersecting) {
                this.props.getNewContent();
                return;
            }
        });
    }

    setupObserver() {
        const options = {
            root: document.getElementById('section'),
            threshold: 1
        };

        // Checks if the anchor is in view
        this.observer = new IntersectionObserver( this.handleObserver, options);

        this.observer.observe(this.anchorRef.current);
    }

    resetScroll() {
        this.containerRef.current.scrollTop = 0;
        this.containerRef.current.scrollLeft = 0;
    }

    componentDidMount() {
        this.setupObserver();
    }

    componentWillUnmount() {
        this.observer.disconnect();
        clearTimeout(this.timer);
    }

    render() {
        return (
            <div className="InfiniteScroll" ref={this.containerRef}>
                {this.props.children}
                <div className="anchor" ref={this.anchorRef}></div>
            </div>
        )
    }
}

export default InfiniteScroll;