// Libraries
import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Video from '../Modules/Video/Video';

// Styling
import './LaunchVideo.scss';

// Assets 
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import Cursor from '../Cursor/Cursor';

class LaunchVideo extends Component {

    state = {
        ready: false,
        page: null
    }

    componentDidMount() {
        Storyblok.fetchShowPage( 'launch' ).then( page => {
            this.setState( { ready: true, page } );
        });
    }

    closeHandler = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {

        if (!this.state.ready) {
            return <div></div>
        }

        const videoData = { "size": "large", source: [{
            component: "video-source-vimeo",
            vimeo_id: this.state.page.vimeo_video_id
        }], adjacent_text:[] };
       
        return (
            <div className="LaunchVideo ready">
                <Cursor>
                    <button type="button" className="close" onClick={this.closeHandler}>
                        <CloseIcon className="close-icon" alt="Close Icon" />
                    </button>
                    <Video className="video" data={videoData} autoPlay={true} finished={this.closeHandler}></Video>
                </Cursor>
            </div>
        )
    }
}

export default LaunchVideo;