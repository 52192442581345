// Libraries
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Components
import Cursor from '../Cursor/Cursor';
import Menu from './Menu/Menu';
import CurrentShow from '../CurrentShow/CurrentShow';

// Styling
import './Header.scss';

// Assets
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';

class Header extends Component {

    state = {
        alternateLogo: false,
        menuOpen: false
    }

    setMenuState(value) {
        this.setState({menuOpen: value});
    }
    
    setLogo() {
        const alternateLogo = this.props.location.pathname.indexOf( '/masterclasses' ) >=0 ? true : false;
        this.setState({alternateLogo});
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setLogo();
        }
    }

    componentDidMount() {
        this.setLogo();
    }

    render() {
        const logo = this.state.alternateLogo ? Storyblok.site.logo_light : Storyblok.site.logo_dark;
        const menu = this.state.menuOpen ? <Menu close={() => this.setMenuState(false)}></Menu> : null

        return (
            <header className="Header">
                <Cursor>
                    <Link to="/" className="logo-link">
                        <img className="logo" src={logo.filename} alt={logo.alt} />
                    </Link>
                    
                    <CurrentShow />

                    <button type="button" className="menu" onClick={() => this.setMenuState(true)}>
                        <Hamburger className="hamburger" alt="Hamburger Icon" />
                    </button>
                    {menu}
                </Cursor>
            </header>
        )
    }
}

export default withRouter(Header);