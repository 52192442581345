import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';
import Session from '../../classes/session';

import './Showcase.scss';

// Component
import Cursor from '../Cursor/Cursor';
import Loader from '../Loader/Loader';

// Assets
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/cta/arrow-right.svg';

class Showcase extends Component {

    constructor(props) {
        super(props);
        this.galleryRef = React.createRef();
    }

    state = {
        id: null,
        gallery: [],
        loading: true,
        prompt: null,
        touchDevice: this.checkIfTouchDevice()
    }

    animationPoints = {
        outside: 40,
        inside: 80,
        minScale: 0.5,
        maxScale: 1
    }

    closeHandler = () => {
        this.setState({id: null, gallery: []});
        this.observer.disconnect();
    }

    onWheelHandler = (event) => {
        // Firefox Does scrolling by lines not pixels. Rough calculation is 1 line is 16px
        if (event.deltaMode === 1) {
            event.deltaX = event.deltaX * 16;
            event.deltaY = event.deltaY * 16;
        }
        if ( Math.abs(event.deltaY) > Math.abs(event.deltaX) ) {
            this.galleryRef.current.scrollLeft += (event.deltaY * 1);
        }
    }

    checkIfTouchDevice() {
        return ('ontouchstart' in window) ? true : false;
    }

    findScaleValue(ratio) {
        const percent = ratio * 100;
        // Check if outside
        if (percent <= this.animationPoints.outside) {
            return this.animationPoints.minScale;
        } else {
            // Check if inside
            if (percent >= this.animationPoints.inside) {
                return this.animationPoints.maxScale;
            } else {
                // check in animation zone
                const percentInside = (percent - this.animationPoints.outside) / (this.animationPoints.inside - this.animationPoints.outside);
                const scale = this.animationPoints.minScale + ((this.animationPoints.maxScale - this.animationPoints.minScale) * percentInside);
                return scale;
            }
        }
    }

    handleObserver = (entries, observer) => {
        entries.forEach( (entry) => {
            const scale = this.findScaleValue(entry.intersectionRatio);
            const child = entry.target.children[0];
            if (child) {
                child.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
            }
        });
    }

    setupObserver() {
        let animationPoints = [];
        for (let i = this.animationPoints.outside; i <= this.animationPoints.inside; i++) {
            animationPoints.push( (i * 0.01) );
        }

        const options = {
            root: null,
            threshold: animationPoints
        };

        // Checks if the anchor is in view
        this.observer = new IntersectionObserver( this.handleObserver, options);

        setTimeout( () => {
            const items = this.galleryRef.current.children;
            Array.from(items).forEach( (item) => this.observer.observe(item));
        })
    }

    scrolled = () => {
        if (this.state.prompt) {
            this.setState({prompt: false});
        }
    }

    setPromptState() {
        this.setState({prompt: this.galleryRef.current.clientWidth < this.galleryRef.current.scrollWidth})
    }

    loadFirstImage(item) {
        if (item) {
            const image = new Image();

            image.onload = () => {
                this.setState({loading: false}, this.setPromptState)
            };

            image.onerror = () => {
                this.setState({loading: false}, this.setPromptState)
            }

            image.src = item.filename;
        } else {
            this.setState({loading: false}, this.setPromptState);
        }
    }

    getData() {
        Storyblok.fetchGallery(this.state.id)
            .then( (data) => {
                Track.page( 'Gallery', '/gallery', {
                    custom_map: {
                        dimension3: 'gallery_name'
                    },
                    gallery_name: data.name
                });

                const getFilename = (path) => path.substr( path.lastIndexOf('/') + 1 ); 

                const gallery = (data.generated_index ? JSON.parse(data.generated_index) : [])
                    .sort( (a,b) => getFilename(a.filename).localeCompare( getFilename(b.filename) ) );

                this.loadFirstImage(gallery[0]);
                this.setState({ gallery }, this.setupObserver())
            });
    }

    openShowcase = (event) => {
        const id = Session.isIE ? event.detail.id : event.detail;
        if (event.detail) {
            this.setState({id, loading: true}, this.getData);
            Track.page( 'Gallery', '/gallery', {
                gallery_name: this.state.name
            });
        }
    }

    componentDidMount() {
        window.addEventListener('showcase', this.openShowcase);
    }

    componentWillUnmount() {
        window.removeEventListener('showcase', this.openShowcase);
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    render() {
        const logo = Storyblok.site.logo_dark;
        const galleryItems = this.state.gallery.map( (item, index) => (
            <div className="gallery-item" key={index}>
                <img className="gallery-image" src={item.filename} alt={item.alt} loading={this.state.gallery.length > 10 ? "lazy" : "auto"} />
            </div>
        ));

        return (
            this.state.id ? (<div className="Showcase">
                <Cursor>
                    { this.state.loading ? (<div className="gallery-loading"><Loader /></div>) : null }
                    <img className="gallery-logo" src={logo.filename} alt={logo.alt} />
                    <button type="button" className="gallery-close" onClick={this.closeHandler}>
                        <CloseIcon className="close-icon" alt="Close Icon" />
                    </button>
                    <div className="gallery-container" ref={this.galleryRef} onWheel={this.onWheelHandler} onScroll={this.scrolled}>
                        {galleryItems}
                    </div>
                    <div className={ !this.state.loading && this.state.prompt ? "cta-action active" : "cta-action" }>
                        { this.state.touchDevice ? (<p className="cta-text">Swipe</p>) : (<p className="cta-text">Scroll</p>) }
                        <RightArrowIcon className="cta-icon" />
                    </div>
                </Cursor>
            </div>) : null
        )
    }
}

export default Showcase;