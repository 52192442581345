import React from 'react';
import './LayoutColumn.scss';
import ModuleBlock from '../ModuleBlock/ModuleBlock';

const LayoutColumn = (props) => {

    const styling = {
        justifyContent: props.data.justify ? props.data.justify : 'flex-start',
        alignItems: props.data.alignment ? props.data.alignment : 'flex-start'
    };
    const classes = props.data.theme ? `LayoutColumn has-theme theme-${props.data.theme}` : 'LayoutColumn';

    return (
        <div className={classes} style={styling}>
            { props.data.items ? props.data.items.map( (item) => <ModuleBlock key={item._uid} data={item} /> ) : null }
        </div>
    )
}

export default LayoutColumn;