// Libraries
import React from 'react';
// import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';
// import Session from '../../classes/session';
import Messages from '../../classes/messages';

// Styling
import './MessageWall.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import Loader from '../../components/Loader/Loader';
import BaseComponent from '../../components/BaseComponent/BaseComponent';
import SubmitMessage from '../../components/SubmitMessage/SubmitMessage';

// Emojis
import Clap from '../../assets/images/emojis/clap.png';
import Fire from '../../assets/images/emojis/fire.png';
import HeartEyes from '../../assets/images/emojis/heart-eyes.png';
import Heart from '../../assets/images/emojis/heart.png';
import Smiley from '../../assets/images/emojis/smiley.png';
import Star from '../../assets/images/emojis/star.png';
import ThumbsUp from '../../assets/images/emojis/thumbs-up.png';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';


class MessageWall extends BaseComponent {

    state = {
        animateInState: true,
        error: null,
        messages: [],
        page: 0,
        loaded: false,
        submitMessage: false
    }

    allMessages = [];
    itemsPerPage = 5;

    openSubmitHandler = () => {
        this.setState({submitMessage: true});
    }

    closeSubmitHandler= () => {
        this.setState({submitMessage: false});
    }

    displayMoreMessages = () => {
        const newPage = this.state.page + 1;
        const messages = this.allMessages ? this.allMessages
            .slice(0, newPage * this.itemsPerPage) : [];
        this.setState({messages, page: newPage});
    }

    getData() {
        Messages.getAll()
            .then( (messages) => this.allMessages = messages)
            .then( () => this.displayMoreMessages() )
            .then( () => this.setState({loaded: true, animateInState: false}))
            .catch( (err) => {
                console.log('Error', err);
                this.setState({loaded: true, animateInState: false, error: true})
            })
    }

    componentDidMount() {
        this.getData();
        Track.page( 'Message Wall', '/message-wall' );
    }

    render() {
        const mainClasses = this.state.animateInState ? 'MessageWall animate-page-in-state' : 'MessageWall';

        const getImage = (id) => {
            switch (id) {
                case 'clap':
                    return (<img className="emoji" src={Clap} alt="Clap Emoji" />)
                case 'fire': 
                    return (<img className="emoji" src={Fire} alt="Fire Emoji" />)
                case 'heartEyes': 
                    return (<img className="emoji" src={HeartEyes} alt="Heart Eyes Emoji" />)
                case 'heart': 
                    return (<img className="emoji" src={Heart} alt="Heart Emoji" />)
                case 'smiley': 
                    return (<img className="emoji" src={Smiley} alt="Smiley Emoji" />)
                case 'star': 
                    return (<img className="emoji" src={Star} alt="Star Emoji" />)
                case 'thumbsUp': 
                    return (<img className="emoji" src={ThumbsUp} alt="Heart Eyes Emoji" />)
                default:
                    return (<img className="emoji" src={Clap} alt="Clap Emoji" />)
            }
        }

        const allowSubmission = Storyblok.site.active_show.name === Storyblok.currentShowName;

        return (
            <Cursor>
                { this.state.loaded ? null : <Loader /> }
                <div className={mainClasses}>
                    { this.state.error ? <p className="regular bold error">There was an error getting the latest messages, please try again</p> : null }
                    <div className="content">
                        <InfiniteScroll ref={this.infiniteScrollRef} getNewContent={this.displayMoreMessages}>
                            <div className="cards">
                                { allowSubmission && this.state.loaded && !this.state.error ? (
                                    <div className="card submit-message theme-red" onClick={this.openSubmitHandler}>
                                        <p className="card-content bold white">Write your message</p>
                                        <p className="card-text white">Click here to submit your<br/>message to the Guestbook</p>
                                    </div>
                                ) : null }
                                { this.state.loaded ? this.state.messages.map( (item) => (
                                    <div className="card" key={item.pk}>
                                        {getImage(item.Emoji)}

                                        {/* When length is 60 or greater add smaller class */}
                                        {item.Content.length > 59 ? (
                                            <p className="bold black card-content smaller">{item.Content}</p>
                                        ) : (
                                            <p className="bold black card-content">{item.Content}</p>
                                        )}
                                        <p className="grey card-name">{item.Name}, {item.Location}</p>
                                    </div>
                                )) : null }
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>

                { this.state.submitMessage ? <SubmitMessage onCloseHandler={this.closeSubmitHandler} /> : null }
            </Cursor>
        );
    }
}

export default MessageWall;