// Libraries
import React, { Component } from 'react';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';
import Track from '../../classes/track';

// Styling
import './Archive.scss';

// Components
import Cursor from '../../components/Cursor/Cursor';
import ScrollDirection from '../../components/ScrollDirection/ScrollDirection';

class Archive extends Component {

    state = {
        animateInState: true,
        shows: Object.values(Storyblok.shows)
    }

    componentDidMount() {
        this.timer = setTimeout( () => this.setState({animateInState: false}) );
        Track.page( 'Archive', '/archive' );
    }

    changeShow(show) {
        Storyblok.showName = show.name;
        this.props.history.push('/');
    }

    render() {
        
        const archiveClasses = this.state.animateInState ? 'Archive animate-page-in-state' : 'Archive';

        const shows = this.state.shows.map( (show) => {
            return show.enabled ? (
                <li key={show.uuid} className={Storyblok.site.active_show.uuid === show.uuid ? 'item current': 'item'}>
                    <button type="button" className="button" onClick={() => this.changeShow(show)}>{show.name}</button>
                </li>
            ) : null;
        });

        return (
            <Cursor>
                <ScrollDirection>
                    <div className={archiveClasses}>
                        <div className="LayoutRow has-theme theme-white" style={{alignItems: 'center'}}>
                            <div className="Text">
                                <div className="text-content">
                                    <h1 className="xxl bold red">See Previous<br />Years Work</h1>
                                </div>
                            </div>
                            <ul className="list">{shows}</ul>
                        </div>
                    </div>
                </ScrollDirection>
            </Cursor>
        );
    }
}

export default Archive;